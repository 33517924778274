import { useState } from 'react';
import { PropTypes } from 'prop-types';
import mixingFoodBowl from 'assets/images/mixingFoodBowl.png';

export default function RecipeImage({ recipeImage, height, width }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="relative">
      <img
        src={recipeImage}
        alt="uploaded food"
        className={`object-cover absolute min-h-full min-w-fill bg-gray-200 rounded-3xl ${height} ${width} ${
          imageLoaded ? 'showImage' : 'hiddenImage'
        }`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = mixingFoodBowl;
        }}
        onLoad={() => {
          setImageLoaded(true);
        }}
      />
      <img
        src={mixingFoodBowl}
        alt="placeholder"
        className={`object-cover min-h-full min-w-fill ${height} ${width}`}
      />
    </div>
  );
}

RecipeImage.propTypes = {
  recipeImage: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

RecipeImage.defaultProps = {
  recipeImage: '',
  height: '',
  width: '',
};
