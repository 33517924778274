import { useEffect } from 'react';
import Welcome from 'components/Welcome';
import RecipesListHolder from 'components/Recipes/RecipesListHolder';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SetLoading, SetAllRecipes } from 'redux/actions';
import { getInitialRecipes } from 'adapters/recipeAdapters';

// Redux

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const recipeToAddLink = useSelector((rdxState) => rdxState.reducers.recipeToAddLink);
  const recipeIdFromUrl = useSelector((rdxState) => rdxState.reducers.recipeIdFromUrl);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const db = useSelector((rdxState) => rdxState.reducers.db);

  const getRecipes = async (showLoading) => {
    if (showLoading) dispatch(SetLoading(true));
    const recipesFromDb = await getInitialRecipes({ db, currentUserId: currentUser.uid });
    dispatch(SetAllRecipes(recipesFromDb));
    dispatch(SetLoading(false));
  };

  if (recipeToAddLink?.length) {
    navigate(`/addrecipe`);
  }

  if (recipeIdFromUrl?.length) {
    navigate(`/recipe/${recipeIdFromUrl}`);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (currentUser?.uid && allRecipes.length === 0) {
      getRecipes(true);
    }
  }, []);

  return (
    <div className="fade-in">{allRecipes.length > 0 ? <RecipesListHolder /> : <Welcome />}</div>
  );
}
