import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
// import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import KeepAwakeSwitch from 'components/KeepAwakeSwitch';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { SetAlertModalData, SetNavbar, Login } from 'redux/actions';
import { signOut } from 'contexts/auth/authFunctions';
import NoSleep from 'nosleep.js';
import './Navbar.css';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const navList = [
  { name: 'recipes', navigateTo: 'recipes' },
  { name: 'add recipe', navigateTo: 'addrecipe' },
  { name: 'shopping list', navigateTo: 'shoppinglist' },
];

export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const noSleep = new NoSleep();

  const themeName = useSelector((rdxState) => rdxState.reducers.themeName);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const showNavbar = useSelector((rdxState) => rdxState.reducers.navbar);
  const numberOfItemsOnShoppingList = useSelector(
    (rdxState) => rdxState.reducers.numberOfItemsOnShoppingList,
  );

  const [shoppingListNumber, setShoppingListNumber] = useState(0);
  const [userImageURL, setUserImageUrl] = useState('');

  const [userEmail, setUserEmail] = useState('');

  // const toggleTheme = () => {
  //   const name = themeName === 'dark' ? 'light' : 'dark';
  //   localStorage.setItem('themeName', name);
  //   setThemeName(name);
  // };

  useEffect(() => {
    if (currentUser.photoURL) {
      setUserImageUrl(currentUser.photoURL);
    }
    setUserEmail(currentUser.email);
  }, []);

  const toggleNavList = () => {
    dispatch(SetNavbar(!showNavbar));
  };
  const closeNavList = () => {
    dispatch(SetNavbar(false));
  };

  const handleNavigate = (navigateTo) => {
    closeNavList();
    navigate(`${navigateTo}`);
  };

  const resetModal = () => {
    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
      }),
    );
  };

  const handleLogOut = async () => {
    const signedOut = await signOut();

    if (signedOut) {
      dispatch(Login({}));
      resetModal();
      navigate('/');
    }
  };
  const handleSignOut = () => {
    dispatch(
      SetAlertModalData({
        state: true,
        actionText: 'Log out',
        actionButtonMode: 'danger',
        cancelText: 'Cancel',
        cancelButtonMode: 'alert',
        bodyText: 'Are you sure you want to Log out?',
        handleFunction: handleLogOut,
      }),
    );
  };

  const keepAwakeFunc = (keepAwake) => {
    if (keepAwake) {
      noSleep.enable();
    } else {
      noSleep.disable();
    }
  };

  useEffect(() => {
    setShoppingListNumber(numberOfItemsOnShoppingList);
  }, [numberOfItemsOnShoppingList]);

  return (
    <nav className="center">
      <div
        style={{ display: showNavbar ? 'flex' : null }}
        className={
          showNavbar
            ? `${themeName}_nav__list__background_color nav__list h-screen`
            : `${themeName}_nav__list__background_color nav__list`
        }
      >
        {navList.map((navOption) => (
          <div key={navOption.name} className="nav-list-item monserrat link link-nav">
            <button
              className={`uppercase fade-in ${
                location.pathname.slice(1) === navOption.navigateTo ? 'font-medium' : 'font-light'
              }`}
              type="button"
              onClick={() => {
                handleNavigate(navOption.navigateTo);
              }}
            >
              {navOption.name}
            </button>
            {shoppingListNumber > 0 && navOption.navigateTo === 'shoppinglist' && (
              <span className="ml-1 font-light">({shoppingListNumber})</span>
            )}
          </div>
        ))}
        {showNavbar && (
          <div className="nav-list-item monserrat link link-nav">
            <button
              className="uppercase fade-in font-light"
              type="button"
              onClick={() => {
                handleSignOut();
              }}
            >
              Sign Out
            </button>
          </div>
        )}
      </div>
      {/* <Tooltip
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        disableInteractive
        title={themeName === 'light' ? 'Dark Theme' : 'Light Theme'}
      >
        <button
          type="button"
          onClick={toggleTheme}
          className="center btn btn-icon"
          aria-label="toggle theme"
        >
          {themeName === 'dark' ? <BrightnessLowIcon /> : <BrightnessHighIcon />}
        </button>
      </Tooltip> */}
      <KeepAwakeSwitch keepAwakeFunc={keepAwakeFunc} />

      <div className="hidden sm:block">
        {userImageURL?.length ? (
          <div className="mr-3">
            <Tooltip
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              disableInteractive
              title={userEmail}
              placement="bottom"
            >
              <div>
                <img
                  id="userPhoto"
                  src={userImageURL}
                  alt="user"
                  className="w-auto h-11 rounded-full border-solid border-orange-500 border-2"
                />
              </div>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <Tooltip
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        disableInteractive
        title="Sign Out"
        className="logout__button"
      >
        <button
          className="fade-in"
          type="button"
          onClick={() => {
            handleSignOut();
          }}
        >
          <LogoutOutlinedIcon />
        </button>
      </Tooltip>
      <button
        type="button"
        onClick={toggleNavList}
        className="btn btn-icon nav__hamburger"
        aria-label="toggle navigation"
      >
        {showNavbar ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  );
}
