import { useNavigate } from 'react-router-dom';
// Components
import Navbar from 'components/Navbar/Navbar';
// Styles
import './Header.css';
// Images
import logoData from 'contexts/logoData';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { SetRecipeId } from 'redux/actions';

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeName = useSelector((rdxState) => rdxState.reducers.themeName);

  const goBackHome = () => {
    dispatch(SetRecipeId(null));
    navigate(`/`);
  };

  return (
    <header id="header" className={`${themeName}-header py-2`}>
      <div className="flex justify-between mx-8 lg:mx-11 xl:mx-56">
        <div className="w-28 md:ml-4 items-center flex">
          <button type="button" className="flex" onClick={() => goBackHome()}>
            <img id="logoOnly" src={logoData.logoOnly} alt="logo" className="w-2/5 h-auto" />
          </button>
        </div>
        <Navbar />
      </div>
    </header>
  );
}
