import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Typography, Paper, Box, Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import isEmpty from 'lodash/isEmpty';
// Images
import mixingFoodBowl from 'assets/images/mixingFoodBowl.png';
// Components
import TextButton from 'components/TextButton';
// Helpers
import { fractions } from 'helpers/measurementCollection';
import { updateCategories } from 'helpers/updateCategoriesUtils';
// Adapters
import { addRecipe, updateRecipe } from 'adapters/recipeAdapters';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  SetAlertModalData,
  SetLoading,
  SetAllRecipes,
  SetRecipeId,
  SetRecipe,
  SetAllCategories,
} from 'redux/actions';
// Utils
import { recipeToEdit, recipeToEditFromURL } from './utilities/recipeToEdit';
// db
import { storage } from '../../../firebase';

export default function RecipeForm({ type, urlToScrape }) {
  const dispatch = useDispatch();
  const imgRef = useRef(null);

  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const loading = useSelector((rdxState) => rdxState.reducers.loading);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const recipe = useSelector((rdxState) => rdxState.reducers.recipe);
  const allCategories = useSelector((rdxState) => rdxState.reducers.allCategories);

  const [imageUpload, setImageUpload] = useState(null);
  const [headerText, setHeaderText] = useState('');
  const createImg = (src) => {
    const img = new Image();
    img.src = src;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
    });
  };

  const squareCenterCrop = (img, size) => {
    let x = 0;
    let y = 0;
    let w = size;
    let h = size;
    if (img.naturalWidth > img.naturalHeight) {
      w = (size * img.naturalWidth) / img.naturalHeight;
      x = -Math.abs(w - size) / 2;
    } else {
      h = (size * img.naturalHeight) / img.naturalWidth;
      y = -Math.abs(h - size) / 2;
    }
    return [x, y, h, w];
  };

  const squareImageSize = 600;
  const resizeImg = async (image) => {
    const canvas = document.createElement('canvas');
    canvas.width = squareImageSize;
    canvas.height = squareImageSize;
    const ctx = canvas.getContext('2d');
    const img = await createImg(image);

    const [x, y, h, w] = squareCenterCrop(img, squareImageSize);
    ctx.drawImage(img, x, y, w, h);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  };

  const uploadImage = async () => {
    if (imageUpload) {
      const imageRef = ref(storage, `recipesImages/${currentUser.uid}/${v4()}`);
      const blob = await resizeImg(imageUpload);
      return uploadBytes(imageRef, blob).then((imageUploadedData) => {
        setImageUpload(null);
        if (imgRef.current) imgRef.current.src = null;
        return getDownloadURL(imageUploadedData.ref).then((url) => url);
      });
    }
    return '';
  };

  const handleImgUpload = (event) => {
    const file = event.target.files[0];
    if (!file.type.startsWith('image/')) return false;
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageUpload(e.target.result);
      imgRef.current.src = e.target.result;
    };
    return reader.readAsDataURL(file);
  };

  const navigate = useNavigate();
  let dataToEdit = {};
  if (type === 'edit') {
    if (!recipe.id) {
      navigate(`/`);
    } else {
      dataToEdit = recipeToEdit(recipe);
    }
  }

  if (type === 'new') {
    if (recipe.id && loading === false) {
      dispatch(SetRecipe({}));
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: dataToEdit });

  const setbyURL = () => {
    const datafromUrl = recipeToEditFromURL(recipe);
    if (!isEmpty(datafromUrl)) {
      setValue('title', datafromUrl.title, { shouldValidate: true, shouldDirty: true });
      setValue('ingredients', datafromUrl.ingredients);
      setValue('instructions', datafromUrl.instructions);
      setValue('description', datafromUrl.description);
      setValue('yield', datafromUrl.yield);
      setValue('totalTime', datafromUrl.totalTime);
      setValue('notes', datafromUrl.notes);
      setValue('categories', datafromUrl.categories);
      setValue('active', datafromUrl.active);
      setValue('image', datafromUrl.image);
      dispatch(SetRecipe({}));
    }
  };

  useEffect(() => {
    if (type === 'newLink' && recipe.title) {
      setbyURL();
    }
  }, [type, recipe.title]);

  const handleNavigate = (recipeData, navigateTo) => {
    dispatch(SetRecipe(recipeData));
    dispatch(SetRecipeId(recipeData.id));
    navigate(`/${navigateTo}`, { recipeData });
  };

  const submitData = async (dataToSubmit) => {
    if (dataToSubmit.title) {
      dispatch(SetLoading(true));
      let docRef;
      if (imageUpload) {
        dataToSubmit.image = await uploadImage();
      }
      if (type === 'new' || type === 'newLink') {
        docRef = await addRecipe({
          db,
          currentUserId: currentUser.uid,
          payload: dataToSubmit,
        });
        const parsedAllRecipes = JSON.parse(JSON.stringify(allRecipes));
        parsedAllRecipes.push(docRef);
        dispatch(SetAllRecipes(parsedAllRecipes));
      }

      if (type === 'edit') {
        docRef = await updateRecipe({
          db,
          currentUserId: currentUser.uid,
          recipeId: recipe.id,
          payload: dataToSubmit,
        });
      }

      const foundIndex = allRecipes.findIndex((x) => x.id === docRef.id);
      const allRecipesTemp = [...allRecipes];
      allRecipesTemp[foundIndex] = docRef;
      dispatch(SetAllRecipes(allRecipesTemp));

      const updatedCategories = await updateCategories(
        docRef,
        currentUser.uid,
        type,
        allCategories,
        recipe,
        db,
      );

      dispatch(SetAllCategories(updatedCategories));
      handleNavigate(docRef, `recipe/${docRef.id}`);
      dispatch(SetLoading(false));
    }
  };

  const combine = (array) => {
    let string = '';
    array.forEach((item) => {
      string += item;
    });
    return string;
  };

  const splitLineIntoArray = (lineData) => lineData.trim().split('\n');

  const onlyString = (string) => string.substring(1).slice(0, -1);

  const defineFinalObject = (array, arrayType) => {
    let index = 0;
    const finalObject = [{ groupName: 'standard', [arrayType]: [], index }];
    array.forEach((item) => {
      const trimmedItem = item.trim();
      if (trimmedItem.length > 0) {
        if (trimmedItem.charAt(0) === '[' && trimmedItem.charAt(trimmedItem.length - 1) === ']') {
          index += 1;
          const cleanedUpString = onlyString(trimmedItem);
          finalObject.push({ groupName: cleanedUpString, [arrayType]: [], index });
        }
      }
    });
    return finalObject;
  };

  const decodeFraction = (rawStr) =>
    rawStr
      .replace(/[\u00A0-\u9999<>]/g, (i) => i.charCodeAt(0))
      .toString()
      .trim();

  const defineIngredients = (lineData) => {
    // separate each line from te TextField into an array of strings
    const ingredientsArray = splitLineIntoArray(lineData);
    // select each item defined as header (by using [])
    const finalIngredientsObject = defineFinalObject(ingredientsArray, 'ingredients');

    let groupIndex = 0;
    ingredientsArray.forEach((ingredient, index) => {
      const trimIngredient = ingredient.trim();
      if (trimIngredient.length > 0) {
        const splitString = trimIngredient.split('');
        let foundIngredientGroup = false;
        if (splitString[0] === '[') {
          foundIngredientGroup = true;
          groupIndex += 1;
        }

        const numbers = [];
        const letters = [];
        const separated = {
          index,
          purchased: false,
        };

        let isLetter = false;
        if (!foundIngredientGroup) {
          splitString.forEach((item) => {
            if (item.match(/[a-z]/i) && !isLetter) isLetter = true;
            if (isLetter) {
              letters.push(item);
            } else {
              numbers.push(item);
            }
          });
          const numberPart = combine(numbers);
          const stringPart = combine(letters);

          if (numberPart) {
            const decodedNumberPart = decodeFraction(numberPart);
            if (fractions[decodedNumberPart]) {
              separated.quantity = fractions[decodedNumberPart].trim();
            } else {
              separated.quantity = numberPart.trim();
            }
          }

          if (stringPart) {
            separated.string = stringPart.trim();
          }

          finalIngredientsObject[groupIndex].ingredients.push(separated);
        }
      }
    });
    return finalIngredientsObject;
  };

  const defineInstructions = (lineData) => {
    const instructionsArray = splitLineIntoArray(lineData);
    const finalInstructionsObject = defineFinalObject(instructionsArray, 'instructions');

    let groupIndex = 0;
    instructionsArray.forEach((instruction) => {
      const trimmedInstruction = instruction.trim();
      if (trimmedInstruction.length > 0) {
        let foundInstructionGroup = false;
        if (trimmedInstruction.charAt(0) === '[') {
          foundInstructionGroup = true;
          groupIndex += 1;
        }
        if (!foundInstructionGroup) {
          finalInstructionsObject[groupIndex].instructions.push(trimmedInstruction);
        }
      }
    });
    return finalInstructionsObject;
  };

  const defineCategories = (categories) => {
    const categoriesFinal = [];
    const categoriesHolder = categories.trim().split(',');

    categoriesHolder.forEach((category) => {
      const item = category.trim().toLowerCase();
      categoriesFinal.push(item);
    });
    return categoriesFinal.filter(String);
  };

  const onSubmit = (recipeFormData) => {
    const dataToSubmit = JSON.parse(JSON.stringify(recipeFormData));
    dataToSubmit.deleted = false;
    if (type === 'edit' && recipe.id) {
      if (recipe.favorite === true) {
        dataToSubmit.favorite = true;
      }
      if (recipe.onShoppingList === true) {
        dataToSubmit.onShoppingList = true;
      }
    } else {
      dataToSubmit.favorite = false;
      dataToSubmit.onShoppingList = false;
    }

    if (type === 'newLink' && urlToScrape) {
      recipeFormData.originalURL = urlToScrape;
      dataToSubmit.originalURL = urlToScrape;
    }

    if (recipeFormData.originalURL) {
      const domain = new URL(recipeFormData.originalURL);
      const { hostname } = domain;
      if (hostname.length) {
        dataToSubmit.source = hostname;
      }
    } else {
      dataToSubmit.source = '';
    }

    dataToSubmit.ingredients = defineIngredients(recipeFormData.ingredients.trim());
    dataToSubmit.instructions = defineInstructions(recipeFormData.instructions.trim());

    dataToSubmit.notes = recipeFormData.notes.trim();

    if (recipeFormData.categories.length > 0) {
      dataToSubmit.categories = defineCategories(recipeFormData.categories.trim());
    } else {
      dataToSubmit.categories = [];
    }
    dataToSubmit.title = dataToSubmit.title.charAt(0).toUpperCase() + dataToSubmit.title.slice(1);
    submitData(dataToSubmit);
  };

  const helperInputs = ['1/4', '1/3', '1/2', '3/4', '°', '[', ']'];

  const addHelperInput = (value, string) => {
    let singleValue = getValues(string);
    singleValue += `${value}`;
    if (value !== '[') {
      singleValue += ' ';
    }
    setValue(string, singleValue);
    document.getElementById(string).focus();
  };

  useEffect(() => {
    if (type === 'new') {
      dispatch(SetLoading(false));
    }
  }, [type]);

  // const setImageDataURL = (dataURL) => {
  //   setImageUpload(dataURL);
  // };

  // const getImageFromUrl = async (e) => {
  //   try {
  //     if (e.target.value) {
  //       await setImageUpload(e.target.value);

  //       let img = document.getElementById('recipeImage');
  //       img.onload = () => {
  //         if (img) {
  //           const canvas = document.createElement('canvas');

  //           canvas.width = img.width;
  //           canvas.height = img.height;
  //           const ctx = canvas.getContext('2d');
  //           const [x, y, h, w] = squareCenterCrop(img, img.width);
  //           ctx.drawImage(img, x, y, w, h);
  //           const dataURL = canvas.toDataURL('image/jpg');
  //           img = null;
  //           setImageDataURL(dataURL);
  //         }
  //       };
  //     } else {
  //       await setImageUpload('');
  //     }
  //   } catch (error) {
  //     console.log('%c +++ RecipeForm - error: ', 'color: orange; font-weight: bold', error);
  //   }
  // };

  const setHeader = () => {
    let header = '';

    if (type === 'new') {
      header = 'Add recipe manually';
    } else if (type === 'newLink') {
      header = 'Recipe information from URL';
    } else {
      header = 'Edit Recipe';
    }
    setHeaderText(header);
  };

  const handleReset = () => {
    reset();
  };

  const cancelModal = () => {
    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
        handleCancel: () => {},
      }),
    );
  };

  const handleFunction = () => {
    cancelModal();
    navigate('/recipes');
  };

  const handleCancel = () => {
    dispatch(
      SetAlertModalData({
        state: true,
        actionText: 'Go to Recipes',
        cancelText: 'Stay here',
        bodyText: 'Cancel with return to Recipes List, ok?',
        handleFunction,
      }),
    );
  };

  useEffect(() => {
    setHeader();
  }, [type]);

  return (
    <div className="mt-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          <Box p={3} py={2}>
            <Box px={3} py={2}>
              <Typography variant="h6" align="center" margin="dense">
                {headerText}
              </Typography>
            </Box>
            <Box py={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="title"
                  label="Title"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('title', { required: true })}
                />
                {errors.exampleRequired && (
                  <span className="text-red-500">This field is required</span>
                )}
              </Grid>
            </Box>

            <Box py={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="description"
                  label="Description"
                  multiline
                  rows={3}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('description')}
                />
              </Grid>
            </Box>

            <Box py={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="ingredients"
                  label="Ingredients"
                  multiline
                  rows={16}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('ingredients')}
                />
              </Grid>
              <Box py={2}>
                <Grid item xs={12} sm={6} className="text-xs">
                  Optional: Use brackets for Ingredient Groups. Example: <b>[Crust]</b> and{'  '}
                  <b>[Filling]</b>
                </Grid>
                <Box py={1}>
                  <Grid item xs={12} sm={6} className="text-xs">
                    <div className="flex justify-between">
                      {helperInputs.map((me) => (
                        <button
                          key={me}
                          type="button"
                          className="text-blue-600"
                          onClick={() => addHelperInput(me, 'ingredients')}
                        >
                          {me}
                        </button>
                      ))}
                    </div>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box py={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="instructions"
                  label="Instructions"
                  multiline
                  rows={16}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('instructions')}
                />
              </Grid>
              <Box py={2}>
                <Grid item xs={12} sm={6} className="text-xs">
                  Optional: Use brackets for Instruction Groups. Example: <b>[Crust]</b> and{'  '}
                  <b>[Filling]</b>
                </Grid>
                <Box py={1}>
                  <Grid item xs={12} sm={6} className="text-xs">
                    <div className="flex justify-between">
                      {helperInputs.map((me) => (
                        <button
                          key={me}
                          type="button"
                          className="text-blue-600"
                          onClick={() => addHelperInput(me, 'instructions')}
                        >
                          {me}
                        </button>
                      ))}
                    </div>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box py={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="yield"
                    label="Servings"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('yield')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="categories"
                    label="Categories"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('categories')}
                  />
                  <span className="text-xs">Separate with commas</span>
                </Grid>
              </Grid>
            </Box>

            <Box py={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="active"
                    label="Active Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('active')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="totalTime"
                    label="Total Time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('totalTime')}
                  />
                </Grid>
              </Grid>
            </Box>

            {type === 'new' || type === 'edit' ? (
              <Box py={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="originalURL"
                    label="Original URL"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('originalURL')}
                  />
                </Grid>
              </Box>
            ) : null}

            <Box py={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="notes"
                  label="Notes"
                  multiline
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('notes')}
                />
              </Grid>
            </Box>

            {type === 'new' || recipe?.image?.includes('firebasestorage') ? null : (
              <Box py={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="image"
                    label="Image URL"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('image')}
                  />
                </Grid>
              </Box>
            )}
            {/* <span className="text-xs">Add a link to an image:</span>
            <Box py={2} className="py-4">
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="imageLink"
                  label="Image Link"
                  onChange={(e) => {
                    getImageFromUrl(e);
                  }}
                />
              </Grid>
            </Box> */}
            <Box py={2} className="py-4">
              <Grid item xs={12} sm={12}>
                {imageUpload ? (
                  <div className="lg:pl-1  h-60 w-60 md:h-96 md:w-96 m-auto">
                    <img
                      className="object-cover min-h-full min-w-fill  h-60 w-60 md:h-96 md:w-96 bg-gray-200"
                      id="recipeImage"
                      ref={imgRef}
                      src={imageUpload}
                      width="300"
                      height="300"
                      alt="That one doesn't work :-( Please try another one"
                    />
                  </div>
                ) : (
                  recipe.image && (
                    <div className="lg:pl-1  h-60 w-60 md:h-96 md:w-96 m-auto">
                      <img
                        className="object-cover min-h-full min-w-fill  h-60 w-60 md:h-96 md:w-96 bg-gray-200"
                        ref={imgRef}
                        src={recipe.image}
                        width="300"
                        height="300"
                        alt="img placeholder"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = mixingFoodBowl;
                        }}
                      />
                    </div>
                  )
                )}
                <div>
                  {/* <div className="text-xs">or upload an image from your computer:</div> */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleImgUpload(e);
                    }}
                    className="py-4"
                  />
                </div>
              </Grid>
            </Box>
            <Box py={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="flex">
                    <TextButton
                      text="Cancel"
                      handleFunction={handleCancel}
                      width="w-27"
                      height="h-12"
                      mode="danger"
                    />
                    <TextButton
                      text="Clear"
                      handleFunction={handleReset}
                      width="w-27"
                      height="h-12"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <TextButton
                    text={type === 'new' ? 'Add Recipe' : 'Save Changes'}
                    handleFunction={handleSubmit(onSubmit)}
                    width="w-27"
                    height="h-12"
                    mode="success"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </form>
    </div>
  );
}

RecipeForm.propTypes = {
  type: PropTypes.string.isRequired,
  urlToScrape: PropTypes.string,
};

RecipeForm.defaultProps = {
  urlToScrape: '',
};
