import { useSelector } from 'react-redux';

export default function RecipeInstructions() {
  const recipe = useSelector((rdxState) => rdxState.reducers.recipe);

  return (
    <div className="my-5 mb-20 lg:pl-3 lg:pl-0">
      <div className="my-5 pl-3 lg:pl-0">
        <div className="mt-5 mb-2 text-2xl">INSTRUCTIONS</div>
        {recipe.instructions.map((item) => (
          <div key={item.index}>
            {item.instructions.length > 0 && (
              <div className="text-1xl font-bold uppercase">{item.groupName}</div>
            )}
            {item.instructions.map((instruction, idx) => (
              <div className="flex mb-2" key={instruction}>
                {item.instructions.length > 1 && <div className="font-bold mr-2">{idx + 1} </div>}
                <div>{instruction}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
