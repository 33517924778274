export default function addItemsToShoppingListTotal(shoppingList) {
  let itemsOnShoppingList = 0;
  shoppingList.forEach((recipe) => {
    if (recipe.onShoppingList) {
      recipe.ingredients.forEach((ingredientGroup) => {
        ingredientGroup.ingredients.forEach((ingredient) => {
          if (ingredient.toAdd) {
            itemsOnShoppingList += 1;
          }
        });
      });
    }
  });
  return itemsOnShoppingList;
}
