import { useEffect, useState } from 'react';
import loadingGif from 'assets/images/loading.gif';
import { random } from 'lodash';
import './LoadingOverlay.css';
// Redux
import { useSelector } from 'react-redux';

const sayings = [
  {
    saying: "We're cooking something up....",
    source: '',
  },
  {
    saying: 'Laughter is brightest where food is best.',
    source: 'Irish Proverb',
  },
  {
    saying: 'Food is a passion. Food is love.',
    source: 'Hector Elizondo',
  },
  {
    saying: 'Life is a combination of magic and pasta.',
    source: 'Federico Fellini',
  },
  {
    saying: 'The way you make an omelet reveals your character.',
    source: 'Anthony Bourdain',
  },
  {
    saying: 'Barbecue may not be the road to world peace, but it’s a start.',
    source: 'Anthony Bourdain',
  },
  {
    saying: 'You learn a lot about someone when you share a meal together.',
    source: 'Anthony Bourdain',
  },
  {
    saying: 'Life is uncertain. Eat dessert first.',
    source: 'Ernestine Ulmer',
  },
  {
    saying: 'All happiness depends on a leisurely breakfast.',
    source: 'John Gunther',
  },
  {
    saying: 'I cook with wine, sometimes I even add it to the food.',
    source: 'W C Fields',
  },
  {
    saying: '...to eat intelligently is an art.',
    source: 'François de la Rochefoucauld',
  },
  {
    saying: 'All sorrows are less with bread.',
    source: 'Don Quixote',
  },
  {
    saying: 'Stress cannot exist in the presence of a pie.',
    source: 'David Mamet',
  },
  {
    saying: 'The greatest dishes are very simple.',
    source: 'Auguste Escoffier',
  },
  {
    saying: 'Without ice cream, there would be darkness and chaos.',
    source: 'Don Kardong',
  },
  {
    saying: 'Cooking well doesn’t mean cooking fancy.',
    source: 'Julia Child',
  },
];

export default function LoadingOverlay() {
  const [selectedSaying, setSelectedSaying] = useState(random(0, sayings.length - 1));
  const loading = useSelector((rdxState) => rdxState.reducers.loading);

  useEffect(() => {
    if (loading === false) {
      setSelectedSaying(sayings[random(0, sayings.length - 1)]);
    }
  }, [loading]);
  return (
    <div>
      {loading && (
        <div className="loading-background-color flex h-full absolute w-full -pt-12 z-600">
          <div className="m-auto">
            <div className="m-auto">
              <img src={loadingGif} alt="loading..." />
            </div>
            <div className="mx-32">
              <div className="flex items-center justify-center text-lg">
                <div className="m-auto">{selectedSaying.saying}</div>
              </div>
              <div className="text-end">
                <div className="m-auto italic">{selectedSaying.source}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
