import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

export default function TextButton({
  text,
  handleFunction,
  width,
  height,
  mode,
  tooltipPosition,
  tooltipText,
}) {
  return (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      disableInteractive
      placement={tooltipPosition}
      title={tooltipText}
    >
      <div className="mt-1">
        <button
          type="button"
          onClick={handleFunction}
          className={`uppercase px-4 py-2 text-xs text-blue-100 duration-300 rounded-xl mx-1
          ${height}
          ${width}
          ${mode === 'default' ? 'hover:bg-gray-500 bg-gray-500' : ''}
          ${mode === 'success' ? 'bg-broccoli hover:bg-broccoli-600' : ''}
          ${mode === 'danger' ? 'bg-tomato hover:bg-tomato-600' : ''}
          ${mode === 'alert' ? 'bg-carrot hover:bg-carrot-600' : ''}`}
        >
          {text}
        </button>
      </div>
    </Tooltip>
  );
}

TextButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleFunction: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  mode: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipText: PropTypes.string,
};

TextButton.defaultProps = {
  width: 'w-min',
  height: 'h-9',
  mode: 'default',
  tooltipPosition: 'bottom',
  tooltipText: '',
};
