import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducers from './reducers';

const RootReducers = combineReducers({ reducers });

const store = configureStore({
  reducer: RootReducers,
  middleware: [thunk],
});

export default store;
