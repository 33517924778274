import { useEffect, useState } from 'react';
import { Checkbox, ClickAwayListener } from '@mui/material';
// Components
import TextButton from 'components/TextButton';
import ShoppingListByGroup from 'components/ShoppingList/ShoppingListByGroup';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetLoading, SetAddToShoppingListData, SetAllRecipes } from 'redux/actions';
// Adapter
import { updateRecipe } from 'adapters/recipeAdapters';
// Styles
import './AddToShoppingListModal.css';
// Hooks
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';

export default function AddToShoppingListModal() {
  const dispatch = useDispatch();
  const updateShoppingList = useUpdateShoppingList();

  const [recipeToUpdate, setRecipeToUpdate] = useState({ ingredients: [] });
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const { state, recipeData, previousScreen, completeActionFunction } = useSelector(
    (rdxState) => rdxState.reducers.AddToShoppingListData,
  );

  const [checked, setChecked] = useState(true);
  const [needToAdd, setNeedToAdd] = useState(false);
  const [itemsToAdd, setItemsToAdd] = useState(0);

  const closeModal = (action, updatedRecipe) => {
    if (previousScreen === 'recipeScreen' && action === 'recipeUpdated') {
      completeActionFunction(updatedRecipe);
    }
    dispatch(
      SetAddToShoppingListData({
        state: false,
        recipeData: {},
        previousScreen: '',
        completeActionFunction: () => {},
      }),
    );
    setChecked(true);
    setNeedToAdd(false);
  };

  const handleSelectAll = (update) => {
    setNeedToAdd(false);
    let count = 0;
    recipeToUpdate.ingredients.forEach((ingredientsGroup) => {
      ingredientsGroup.ingredients.forEach((ingredient) => {
        ingredient.toAdd = update;
        count += 1;
      });
    });

    if (update) {
      setItemsToAdd(count);
    } else {
      setItemsToAdd(0);
    }
    setRecipeToUpdate({ ...recipeToUpdate });
    setChecked(!checked);
  };

  const updateAddToShoppingListItem = (ingredient) => {
    setNeedToAdd(false);
    let count = 0;
    const parsedRecipeToEdit = JSON.parse(JSON.stringify(recipeToUpdate));

    parsedRecipeToEdit.ingredients[ingredient.ingredientsGroupIndex].ingredients.forEach(
      (recipeIng, idx) => {
        if (ingredient.string === recipeIng.string) {
          parsedRecipeToEdit.ingredients[ingredient.ingredientsGroupIndex].ingredients[idx].toAdd =
            ingredient.toAdd;
        }
        if (
          parsedRecipeToEdit.ingredients[ingredient.ingredientsGroupIndex].ingredients[idx].toAdd
        ) {
          count += 1;
        }
      },
    );

    setItemsToAdd(count);
    setRecipeToUpdate({ ...parsedRecipeToEdit });
  };

  const updateAllRecipes = (updatedRecipe) => {
    const recipeFoundInAllRecipesIndex = allRecipes.findIndex(
      (element) => element.id === recipeToUpdate.id,
    );
    const parsedAllRecipes = JSON.parse(JSON.stringify(allRecipes));
    parsedAllRecipes[recipeFoundInAllRecipesIndex] = updatedRecipe;
    dispatch(SetAllRecipes(parsedAllRecipes));
  };

  const handleSetNeedToAdd = () => {
    setNeedToAdd(true);
    setTimeout(() => {
      setNeedToAdd(false);
    }, 2500);
  };

  const handleAddToShoppingList = async () => {
    let toAddFound = false;
    recipeToUpdate.ingredients.forEach((group) => {
      group.ingredients.forEach((ingredient) => {
        if (ingredient.toAdd) {
          toAddFound = true;
        }
      });
    });
    if (!toAddFound) {
      handleSetNeedToAdd();
      return;
    }
    dispatch(SetLoading(true));

    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: recipeToUpdate.id,
      payload: {
        onShoppingList: true,
        ingredients: recipeToUpdate.ingredients,
      },
    });
    if (updatedRecipe) {
      updateAllRecipes(updatedRecipe);
      closeModal('recipeUpdated', updatedRecipe);
      await updateShoppingList();
    }
    dispatch(SetLoading(false));
  };

  const handleSetRecipeToUpdate = () => {
    let count = 0;
    if (state && recipeData?.ingredients) {
      recipeData?.ingredients.forEach((ingredientsGroup) => {
        ingredientsGroup?.ingredients.forEach((ingredient) => {
          ingredient.toAdd = true;
          count += 1;
        });
      });
      setItemsToAdd(count);
      setRecipeToUpdate({ ...recipeData });
    }
  };

  useEffect(() => {
    handleSetRecipeToUpdate();
  }, [state]);

  return (
    <div>
      {state ? (
        <div
          style={{ background: 'rgba(255, 255, 255, 0.9)' }}
          className="flex fixed h-full w-full z-500"
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={closeModal}
          >
            <div className="w-11/12 h-5/6 md:w-[500px] md:h-4/5 m-auto fixed top-0 bottom-0 left-0 right-0">
              <div className="overflow-auto absolute top-0 bottom-0 w-full bg-gray-100 rounded-3xl border-solid border-2 border-gray-300">
                <header className="w-full bg-white pt-6 pb-2 px-4">
                  Add to Shopping List
                  <div className="flex flex-col pt-2">
                    <div className="text-2xl text-center">{recipeToUpdate?.title}</div>
                    <div className="mt-1 text-center">
                      {recipeToUpdate?.onShoppingList && (
                        <div className="italic text-sm">* Recipe already on Shopping List</div>
                      )}
                    </div>
                  </div>
                  <div className="ml-0 flex flex-row">
                    <Checkbox
                      color="success"
                      type="checkbox"
                      checked={checked}
                      onChange={() => handleSelectAll(!checked)}
                    />
                    <div className="mt-2">{!checked ? 'Deselect All' : 'Select all'}</div>
                  </div>
                </header>
                <div
                  className={`absolute overflow-y-scroll bottom-14 w-full ${
                    recipeToUpdate?.onShoppingList ? 'top-44' : 'top-40'
                  }`}
                >
                  <div className={`${recipeToUpdate?.onShoppingList ? 'mt-1' : '-mt-1'} mb-8`}>
                    <div className="pl-4">Items to add</div>
                    <ShoppingListByGroup
                      shoppingListData={[recipeToUpdate]}
                      updateAddToShoppingListItem={updateAddToShoppingListItem}
                      addToShoppingList
                    />
                  </div>
                </div>
                <footer className="flex justify-around align-middle absolute bg-white bottom-0 w-full m-h-22 p-2">
                  <div className="flex flex-col w-full justify-around align-middle">
                    {needToAdd && (
                      <div className="flex justify-center bg-red-200 align-middle text-center w-full">
                        <div className="py-1 flex text-tomato align-middle">
                          Please, add at least one ingredient to the shopping list
                        </div>
                      </div>
                    )}
                    <div className="flex justify-around w-full">
                      <TextButton
                        text="cancel"
                        handleFunction={closeModal}
                        width="w-27"
                        height="h-9"
                        mode="danger"
                      />
                      <TextButton
                        text={`Add ${itemsToAdd} item${itemsToAdd !== 1 ? 's' : ''}`}
                        handleFunction={handleAddToShoppingList}
                        width="w-32"
                        height="h-9"
                        mode="success"
                      />
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      ) : null}
    </div>
  );
}
