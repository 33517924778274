import { doc, getDoc } from 'firebase/firestore';

export async function getUserData(data) {
  const docSnap = await getDoc(doc(data.db, `users/${data.currentUserId}`));

  if (docSnap.exists()) {
    return docSnap.data();
  }

  return false;
}

export async function updateUserData() {
  return true;
}
