import {
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  query,
  orderBy,
  where,
} from 'firebase/firestore';

export async function getDataByIdInternal(data) {
  const docSnap = await getDoc(data.docRef);

  const category = docSnap.data();
  category.id = data.categoryId;

  return docSnap.exists() ? category : false;
}

export async function getAllCategories(data) {
  const categories = await getDocs(
    query(collection(data.db, `users/${data.currentUserId}/categories/`), orderBy('name')),
  );
  const itemsWithIds = [];
  categories.forEach((category) => {
    const item = category.data();
    item.id = category.id;
    itemsWithIds.push(item);
  });

  return itemsWithIds;
}

export async function addCategory(data) {
  const newCategory = await addDoc(
    collection(data.db, `users/${data.currentUserId}/categories`),
    data.payload,
  );

  const docRef = doc(data.db, `users/${data.currentUserId}/categories/${newCategory.id}`);

  return getDataByIdInternal({ docRef, categoryId: newCategory.id });
}

export async function updateCategory(data) {
  const docRef = doc(data.db, `users/${data.currentUserId}/categories/${data.categoryId}`);

  await updateDoc(docRef, data.payload);

  return getDataByIdInternal({ docRef, categoryId: data.categoryId });
}

export async function deleteCategory(data) {
  const docRef = await doc(data.db, `users/${data.currentUserId}/categories/${data.categoryId}`);
  await deleteDoc(docRef);

  return true;
}

export async function getNonDeletedRecipesByCategories(data) {
  const categories = await getDocs(
    query(
      collection(data.db, `users/${data.currentUserId}/recipes/`),
      where('categories', 'array-contains', data.payload),
      where('deleted', '==', false),
    ),
  );

  const itemsWithIds = [];
  categories.forEach((category) => {
    const item = category.data();
    item.id = category.id;
    itemsWithIds.push(item);
  });
  return itemsWithIds;
}
