import { Box, Grid, Modal, Fade, Typography, ClickAwayListener } from '@mui/material';

import TextButton from 'components/TextButton';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { SetAlertModalData } from '../../redux/actions';

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 220,
  bgcolor: 'background.paper',
};

export default function AlertModal() {
  const dispatch = useDispatch();
  const {
    state,
    bodyText,
    actionText,
    handleFunction,
    actionButtonMode,
    secondaryText,
    handleSecondaryFunction,
    secondaryButtonMode,
    cancelText,
    cancelButtonMode,
  } = useSelector((rdxState) => rdxState.reducers.alertModalData);

  const handleClose = () => {
    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
      }),
    );
  };

  const handleOnClick = () => {
    handleFunction();
    handleClose();
  };

  const handleOnCancel = () => {
    handleClose();
  };

  return (
    <div className="z-400">
      <div className="flex h-full w-full -pt-12">
        <div className="m-auto">
          <div>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClose}
            >
              <Modal
                open={state}
                onClose={handleClose}
                closeAfterTransition
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={state}>
                  <Box className="absolute p-10 rounded-2xl" sx={style}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <div className="text-2xl monserrat uppercase">{actionText}</div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography align="center">{bodyText}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="flex justify-around">
                          {cancelText && (
                            <Box justify="flex-start" textAlign="right">
                              <TextButton
                                text={cancelText}
                                handleFunction={handleOnCancel}
                                width="w-27"
                                height="h-12"
                                mode={cancelButtonMode}
                              />
                            </Box>
                          )}
                          {secondaryText && (
                            <Box justify="flex-end" textAlign="right">
                              <TextButton
                                text={secondaryText}
                                handleFunction={handleSecondaryFunction}
                                width="w-27"
                                height="h-12"
                                mode={secondaryButtonMode}
                              />
                            </Box>
                          )}
                          {actionText && (
                            <Box justify="flex-end" textAlign="right">
                              <TextButton
                                text={actionText}
                                handleFunction={handleOnClick}
                                width="w-27"
                                height="h-12"
                                mode={actionButtonMode}
                              />
                            </Box>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
            </ClickAwayListener>
          </div>
        </div>
      </div>
    </div>
  );
}
