// React
import React from 'react';
// Router
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// Components
import SupportBubble from 'components/SupportBubble';

// Pages
import SignIn from 'pages/SignIn';
import Privacy from 'pages/Privacy';

export default function RouterHolder() {
  return (
    <div id="router" className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <SupportBubble />
      </Router>
    </div>
  );
}
