import {
  addCategory,
  getAllCategories,
  getNonDeletedRecipesByCategories,
  deleteCategory,
} from 'adapters/categoriesAdapters';
import { isEmpty } from 'lodash';

export const defineCategoriesToUpdate = (categoriesToUpdate, allCategories, recipe, type) => {
  const toAdd = [];
  let toUpdate = [];
  // Find collision for newly added categories
  const newCategoriesToAdd = categoriesToUpdate.filter(
    (x) => !allCategories.some((o) => o.name === x),
  );

  // Add new categories not found in allCategories for new and edit modes
  newCategoriesToAdd.forEach((name) => {
    toAdd.push({
      name,
    });
  });

  if (type === 'edit') {
    // Get categories that were removed from the recipe
    toUpdate = recipe.categories.filter((name) => !categoriesToUpdate.includes(name));
  }

  return { toAdd, toUpdate };
};

export const defineCategoriesToRemove = async (
  db,
  recipeCategories,
  allCategories,
  userId,
  returnAllCategories,
) => {
  let allCategoriesArray = allCategories;
  if (isEmpty(allCategories)) {
    allCategoriesArray = await getAllCategories({
      db,
      currentUserId: userId,
    });
  }

  const toUpdatePromises = [];
  recipeCategories.forEach((category) => {
    toUpdatePromises.push(
      getNonDeletedRecipesByCategories({
        db,
        currentUserId: userId,
        payload: category,
      }),
    );
  });
  const recipesFound = await Promise.all(toUpdatePromises);

  const categoriesToRemove = [];
  recipeCategories.forEach((categoryRemoved, index) => {
    if (!recipesFound[index].length) {
      categoriesToRemove.push(allCategoriesArray.find((cat) => cat.name === categoryRemoved));
    }
  });

  const toRemovePromises = [];
  categoriesToRemove.forEach((category) => {
    toRemovePromises.push(
      deleteCategory({
        db,
        currentUserId: userId,
        categoryId: category.id,
      }),
    );
  });
  await Promise.all(toRemovePromises);

  if (returnAllCategories) {
    return getAllCategories({
      db,
      currentUserId: userId,
    });
  }
  return true;
};

export const updateCategories = async (recipeToUpdate, userId, type, allCategories, recipe, db) => {
  const { toAdd, toUpdate } = defineCategoriesToUpdate(
    recipeToUpdate.categories,
    allCategories,
    recipe,
    type,
  );

  if (toAdd.length) {
    const addPromises = [];

    toAdd.forEach((newCategory) => {
      addPromises.push(
        addCategory({
          db,
          currentUserId: userId,
          payload: newCategory,
        }),
      );
    });

    await Promise.all(addPromises);
  }

  if (toUpdate.length) {
    await defineCategoriesToRemove(db, toUpdate, allCategories, userId, false);
  }

  return getAllCategories({
    db,
    currentUserId: userId,
  });
};
