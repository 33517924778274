import { useNavigate } from 'react-router-dom';
import TextButton from 'components/TextButton';

export default function Welcome() {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate(`/addrecipe`);
  };
  return (
    <div className="flex">
      <div className="absolute inset-0 flex justify-center items-center z-10 pt-16">
        <div>
          <div className="flex justify-center items-center">
            <div className="w-3/4 lg:w-2/3">
              <div className="my-3">
                <p className="text-center text-2xl">Welcome!</p>
              </div>
              <div className="my-3">
                <p className="text-center text-xl">
                  We created ShopReceta to help you store all your favorite recipes, plan new things
                  to cook, add ingredients to your own personalized Shopping List, and share meals
                  with others. All with one simple tool.
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mt-4 mb-10">
            <TextButton
              text="add your first recipe"
              handleFunction={handleSubmit}
              width="w-42"
              height="h-14"
              mode="success"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
