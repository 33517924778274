import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
// components
import RecipeImage from 'components/Recipe/RecipeImage';
// Redux
import { useSelector } from 'react-redux';

export default function RecipeInfo({ hideInfoAndOptions }) {
  const navigate = useNavigate();
  const recipe = useSelector((rdxState) => rdxState.reducers.recipe);

  const goBackHome = () => {
    navigate(`/`);
  };

  useEffect(() => {
    if (isEmpty(recipe)) goBackHome();
  }, []);

  return (
    <div className="lg:flex">
      <div className="flex">
        <div className="lg:pl-1 md:h-96 md:w-96 h-72 w-72 m-auto">
          <RecipeImage recipeImage={recipe.image} height="md:h-96 h-72" width="md:w-96 w-72" />
        </div>
      </div>
      <div className="w-full ml-0 lg:ml-4">
        <div className="mt-4 pl-3 lg:pl-0">
          {recipe.originalURL && (
            <div className="mt-4">
              <a
                className="text-xs text-blue-400 uppercase"
                href={recipe.originalURL}
                target="_blank"
                rel="noreferrer"
              >
                {recipe.source}
              </a>
            </div>
          )}
          <div className="mb-3">
            <div className="flex">
              <div className="flex mr-5 lg:mr-0">
                <div>
                  <button
                    type="button"
                    className="text-3xl mb-2 capitalize italic"
                    onClick={hideInfoAndOptions}
                  >
                    {recipe.title}
                  </button>
                  {Array.isArray(recipe?.categories) && recipe?.categories?.length > 0 && (
                    <div className="text-xs flex">
                      {recipe.categories.map((category, idx) => (
                        <div key={category} className="capitalize mr-1">
                          {category}
                          {idx + 1 < recipe.categories.length ? ',' : null}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-4/5">
            {recipe.description && (
              <div className="mb-2">
                <b>Description</b>
                <p>{recipe.description}</p>
              </div>
            )}
            {recipe.yield && (
              <div className="mb-2">
                <b className="uppercase">Yield </b>
                {recipe.yield}
              </div>
            )}
            {recipe.active && (
              <div className="mb-2">
                <b className="uppercase">Active Time </b>
                {recipe.active}
              </div>
            )}
            {recipe.totalTime && (
              <div className="mb-2">
                <b className="uppercase">Total Time </b> {recipe.totalTime}
              </div>
            )}
            {recipe.notes && (
              <div className="mb-2">
                <b className="uppercase">Notes </b>
                <div>{recipe.notes}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

RecipeInfo.propTypes = {
  hideInfoAndOptions: PropTypes.func.isRequired,
};
