import { useNavigate } from 'react-router-dom';
import { updateRecipe } from 'adapters/recipeAdapters';
import IconButton from 'components/IconButton';
import { defineCategoriesToRemove } from 'helpers/updateCategoriesUtils';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  SetAlertModalData,
  SetLoading,
  SetAllRecipes,
  SetUpdatingFavorite,
  SetUpdatingShopping,
  SetRecipeId,
  SetRecipe,
  SetAllCategories,
  SetAddToShoppingListData,
} from 'redux/actions';
// Hooks
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';

export default function RecipeOptions() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const updateShoppingList = useUpdateShoppingList();

  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const updatingFavorite = useSelector((rdxState) => rdxState.reducers.updatingFavorite);
  const updatingShopping = useSelector((rdxState) => rdxState.reducers.updatingShopping);
  const recipe = useSelector((rdxState) => rdxState.reducers.recipe);
  const allCategories = useSelector((rdxState) => rdxState.reducers.allCategories);

  const addRecipeDataToRedux = (recipeToUpdate) => {
    dispatch(SetRecipe(recipeToUpdate));
    dispatch(SetRecipeId(recipeToUpdate.id));
  };

  const editRecipe = (recipeToUpdate) => {
    addRecipeDataToRedux(recipeToUpdate);
    navigate(`/editrecipe`);
  };

  const updateAllRecipesData = (updatedRecipe) => {
    const recipeIndex = allRecipes.findIndex((savedRecipe) => savedRecipe.id === recipe.id);
    const newRecipesArray = [...allRecipes];
    newRecipesArray[recipeIndex] = updatedRecipe;
    dispatch(SetRecipe(updatedRecipe));
    dispatch(SetRecipeId(updatedRecipe.id));
    dispatch(SetAllRecipes([...newRecipesArray]));
  };

  const addDataToAUseAddToShoppingList = () => {
    dispatch(
      SetAddToShoppingListData({
        state: true,
        recipeData: recipe,
        previousScreen: 'recipeScreen',
        completeActionFunction: addRecipeDataToRedux,
      }),
    );
  };

  const removeFromShoppingList = async () => {
    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
      }),
    );
    dispatch(SetUpdatingShopping(true));

    const dataToUpdate = {
      onShoppingList: false,
    };
    recipe.ingredients.forEach((ingredientsGroup) => {
      ingredientsGroup.ingredients.forEach((ingredient) => {
        ingredient.purchased = false;
      });
    });

    dataToUpdate.ingredients = recipe.ingredients;
    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: recipe.id,
      payload: dataToUpdate,
    });

    if (updatedRecipe) {
      updateAllRecipesData(updatedRecipe);
      addRecipeDataToRedux(updatedRecipe);
      await updateShoppingList();
    }
    dispatch(SetUpdatingShopping(false));
  };

  const addToShoppingList = () => {
    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
      }),
    );
    addDataToAUseAddToShoppingList();
  };

  const handleAddToShopping = () => {
    if (recipe.onShoppingList) {
      dispatch(
        SetAlertModalData({
          state: true,
          actionText: 'update shopping list',
          actionButtonMode: 'success',
          secondaryText: 'remove',
          secondaryButtonMode: 'alert',
          cancelText: 'cancel',
          cancelButtonMode: 'danger',
          bodyText: 'Do you want to remove this recipe from the Shopping List?',
          handleFunction: addToShoppingList,
          handleSecondaryFunction: removeFromShoppingList,
        }),
      );
    } else {
      addDataToAUseAddToShoppingList();
    }
  };

  const handleUpdateFavorite = async () => {
    dispatch(SetUpdatingFavorite(true));

    const parsedRecipe = JSON.parse(JSON.stringify(recipe));
    let dataToUpdate = {};

    dataToUpdate = {
      favorite: !parsedRecipe.favorite,
    };

    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: parsedRecipe.id,
      payload: dataToUpdate,
    });
    dispatch(SetUpdatingFavorite(false));
    updateAllRecipesData(updatedRecipe);
  };

  const deleteRecipe = async () => {
    dispatch(SetLoading(true));
    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: recipe.id,
      payload: {
        deleted: true,
      },
    });
    const updatedCategories = await defineCategoriesToRemove(
      db,
      updatedRecipe.categories,
      allCategories,
      currentUser.uid,
      true,
    );

    dispatch(SetAllCategories(updatedCategories));

    updateAllRecipesData(updatedRecipe);
    dispatch(SetLoading(false));
    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
      }),
    );
    navigate(`/`);
  };

  const handleDeleteRecipe = () => {
    dispatch(
      SetAlertModalData({
        state: true,
        actionText: 'delete recipe',
        cancelText: 'cancel',
        bodyText: 'Are you sure you want to delete this recipe?',
        handleFunction: deleteRecipe,
      }),
    );
  };

  return (
    <div className="flex flex-wrap pt-0.5 lg:pt-4 mx-2 lg:mx-0">
      <IconButton
        type="favorite"
        itemToUpdate={recipe.favorite}
        updating={updatingFavorite}
        handleFunction={() => handleUpdateFavorite()}
        tooltipText="Add to Favorites"
      />
      <IconButton
        type="onShoppingList"
        itemToUpdate={recipe.onShoppingList}
        updating={updatingShopping}
        handleFunction={() => handleAddToShopping()}
        tooltipText="Add to Shopping List"
      />
      <IconButton type="edit" handleFunction={() => editRecipe(recipe)} tooltipText="Edit Recipe" />
      <IconButton
        type="delete"
        handleFunction={() => handleDeleteRecipe()}
        tooltipText="Delete Recipe"
      />
    </div>
  );
}
