import { useEffect } from 'react';
import ShoppingListHolder from 'components/ShoppingList/ShoppingListHolder';
import AddToShoppingListInput from 'components/ShoppingList/AddToShoppingListInput';
// Hooks
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetLoading } from 'redux/actions';

export default function ShoppingList() {
  const dispatch = useDispatch();
  const updateShoppingList = useUpdateShoppingList();

  const shoppingListData = useSelector((rdxState) => rdxState.reducers.shoppingListData);

  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const numberOfItemsOnShoppingList = useSelector(
    (rdxState) => rdxState.reducers.numberOfItemsOnShoppingList,
  );

  const getShoppingListRecipes = async (showLoader = false) => {
    if (showLoader) {
      dispatch(SetLoading(true));
    }
    await updateShoppingList();
    dispatch(SetLoading(false));
  };

  useEffect(() => {
    if (currentUser) {
      getShoppingListRecipes(true);
    }
  }, [currentUser]);

  return (
    <div className="fade-in flex-justify-center">
      <div className="mb-5 bg-white rounded-3xl border-solid border-2 border-gray-100 lg:w-1024 m-auto">
        <div className="flex px-4 my-8 mb-8">
          <div className="grow md:grow-0 md:flex md:justify-evenly items-center md:mx-auto">
            <div className="md:mx-10">
              <div className="text-2xl">Shopping List</div>
              {numberOfItemsOnShoppingList > 0 && (
                <div>
                  {numberOfItemsOnShoppingList}{' '}
                  {numberOfItemsOnShoppingList === 1 ? 'item' : 'items'}
                </div>
              )}
            </div>
            <div className="flex flex-grow justify-center mt-3 md:mt-0">
              <div className="w-full">
                <AddToShoppingListInput />
              </div>
            </div>
          </div>
        </div>
        {shoppingListData.length > 0 ? (
          <ShoppingListHolder getShoppingListRecipes={getShoppingListRecipes} />
        ) : (
          <div className="mx-3 mb-5 flex justify-center">
            <div>
              <div className="text-2xl pt-1 mr-12 uppercase pt-9">
                There are no items on the Shopping List
              </div>
              <div className="text-xl pt-3 mr-12">
                Add some recipes to the list to see all their ingredients organized by aisle or by
                recipe here, or add individual items manually.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
