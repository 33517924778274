/* eslint-disable prettier/prettier */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const NAVBAR = 'NAVBAR';
export const THEMENAME = 'THEMENAME';
export const ALLRECIPES = 'ALLRECIPES';
export const LOADING = 'LOADING';
export const LOADINGSMALL = 'LOADINGSMALL';
export const RECIPE = 'RECIPE';
export const RECIPEID = 'RECIPEID';
export const REACHEDLAST = 'REACHEDLAST';
export const ALLCATEGORIES = 'ALLCATEGORIES';
export const NUMBEROFITEMSONSHOPPINGLIST = 'NUMBEROFITEMSONSHOPPINGLIST';
export const ALERTMODALDATA = 'ALERTMODALDATA';
export const SHOPPINGLISTDATA = 'SHOPPINGLISTDATA';
export const ADDTOSHOPPINGLISTDATA = 'ADDTOSHOPPINGLISTDATA';
export const RECIPETOADDLINK = 'RECIPETOADDLINK';
export const UPDATINGFAVORITE = 'UPDATINGFAVORITE';
export const UPDATINGSHOPPING = 'UPDATINGSHOPPING';
export const RECIPEIDFROMURL = 'RECIPEIDFROMURL';
