import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import foodsCollection from 'helpers/foodsCollection';
import shopppingGroups from 'helpers/shoppingGroups';
import { updateRecipe } from 'adapters/recipeAdapters';
import ShoppingListItem from 'components/ShoppingList/ShoppingListItem';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetLoading } from 'redux/actions';

export default function ShoppingListByGroup({
  shoppingListData,
  getShoppingListRecipes,
  updateAddToShoppingListItem,
  addToShoppingList,
}) {
  const dispatch = useDispatch();
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);

  const [ingredientGroups, setIngredientGroups] = useState([]);

  const checkShowGroup = (groudId) => {
    const groupToUpdate = ingredientGroups.find((x) => x.id === groudId);
    let showGroup = false;
    groupToUpdate.ingredients.forEach((ing) => {
      if (!ing.purchased && ing.toAdd) {
        showGroup = true;
      }
    });
    groupToUpdate.showGroup = showGroup;
    const foundIndex = ingredientGroups.findIndex((x) => x.id === groupToUpdate.id);
    ingredientGroups[foundIndex] = groupToUpdate;
    setIngredientGroups([...ingredientGroups]);
  };

  const updateShowGroup = (groudId) => {
    if (!addToShoppingList) {
      const foundIndex = ingredientGroups.findIndex((x) => x.id === groudId);
      ingredientGroups[foundIndex].showGroup = !ingredientGroups[foundIndex].showGroup;
      setIngredientGroups([...ingredientGroups]);
    }
  };

  const sortIngredients = () => {
    dispatch(SetLoading(true));
    const allIngredients = {};

    shoppingListData?.forEach((recipe) => {
      recipe.ingredients?.forEach((ingredientsGroup, index) => {
        ingredientsGroup.ingredients?.forEach((ingredient) => {
          const newIngredient = JSON.parse(JSON.stringify(ingredient));
          newIngredient.recipeObj = recipe;
          newIngredient.ingredientsGroupIndex = index;
          newIngredient.tempIndex = `${recipe.id}-${index}-${ingredient.index}`;
          allIngredients[newIngredient.string] = newIngredient;
        });
      });
    });
    const shopppingGroupsCopy = JSON.parse(JSON.stringify(shopppingGroups));
    foodsCollection.forEach((food) => {
      const groupId = food.shoppingGroupId;
      Object.keys(allIngredients).forEach((ingredient) => {
        const ingredientString = allIngredients[ingredient]?.string?.toLowerCase();
        const ingredientWithoutParenthesis = ingredient.split('(')[0];
        const found = ingredientWithoutParenthesis
          .toLowerCase()
          .match(food.name.toLocaleLowerCase());
        if (found) {
          if (!shopppingGroupsCopy[groupId].ingredients[ingredientString]) {
            shopppingGroupsCopy[groupId].ingredients[ingredientString] = allIngredients[ingredient];
            delete allIngredients[ingredient];
            delete shopppingGroupsCopy[0].ingredients[ingredientString];
          }
        } else {
          shopppingGroupsCopy[0].ingredients[ingredientString] = allIngredients[ingredient];
        }
      });
    });

    const ingredientsArray = [];

    Object.keys(shopppingGroupsCopy).forEach((group) => {
      if (!isEmpty(shopppingGroupsCopy[group].ingredients)) {
        shopppingGroupsCopy[group].showGroup = true;
        let toAdd = false;
        Object.keys(shopppingGroupsCopy[group].ingredients).forEach((item) => {
          if (!toAdd) {
            toAdd = shopppingGroupsCopy[group].ingredients[item].toAdd;
          }
        });
        if (addToShoppingList || toAdd) {
          ingredientsArray.push(shopppingGroupsCopy[group]);
        }
      }
    });

    const sortedArray = orderBy(ingredientsArray, ['id'], ['asc']);
    sortedArray.forEach((item) => {
      item.ingredients = Object.values(item.ingredients);
    });
    const finalArray = JSON.parse(JSON.stringify(sortedArray));
    setIngredientGroups(finalArray);
    dispatch(SetLoading(false));
  };

  const updateShoppingListRecipe = async (ingredient, groupId) => {
    const recipeId = ingredient.recipeObj.id;
    const recipeToEdit = shoppingListData.find((x) => x.id === recipeId);
    const parsedRecipeToEdit = JSON.parse(JSON.stringify(recipeToEdit));

    parsedRecipeToEdit.ingredients[ingredient.ingredientsGroupIndex].ingredients.forEach(
      (recipeIng, idx) => {
        if (ingredient.string === recipeIng.string) {
          parsedRecipeToEdit.ingredients[ingredient.ingredientsGroupIndex].ingredients[
            idx
          ].purchased = ingredient.purchased;
        }
      },
    );

    await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: parsedRecipeToEdit.id,
      payload: parsedRecipeToEdit,
    });
    await getShoppingListRecipes();
    checkShowGroup(groupId);
  };

  useEffect(() => {
    sortIngredients();
  }, [shoppingListData]);

  const defineIngredientGroupCount = (groupIngredients) =>
    groupIngredients.reduce((count, ing) => count + (ing.toAdd ? 1 : 0), 0);

  return (
    <div>
      {ingredientGroups.map((group) => (
        <div key={group.id}>
          <button type="button" onClick={() => updateShowGroup(group.id)}>
            <div className="text-xl pl-3 mt-3 mb-1 capitalize italic">
              {group.name}
              <span className="text-base ml-1.5">
                ({defineIngredientGroupCount(group.ingredients)})
              </span>
            </div>
          </button>
          <div className={!group.showGroup ? 'hidden' : 'block'}>
            {group.ingredients.map((ingredient, index) => (
              <ShoppingListItem
                groupId={group.id}
                key={ingredient.tempIndex}
                index={index}
                ingredient={ingredient}
                addToShoppingList={addToShoppingList}
                updateShoppingListRecipe={
                  addToShoppingList ? updateAddToShoppingListItem : updateShoppingListRecipe
                }
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

ShoppingListByGroup.propTypes = {
  shoppingListData: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  getShoppingListRecipes: PropTypes.func,
  updateAddToShoppingListItem: PropTypes.func,
  addToShoppingList: PropTypes.bool,
};

ShoppingListByGroup.defaultProps = {
  getShoppingListRecipes: () => {},
  updateAddToShoppingListItem: () => {},
  addToShoppingList: false,
};
