export const measurements = {
  ounce: 'ounce',
  gram: 'gram',
  pound: 'pound',
  kilogram: 'kilogram',
  pinch: 'pinch',
  liter: 'liter',
  'fluid ounce': 'fluid ounce',
  gallon: 'gallon',
  pint: 'pint',
  quart: 'quart',
  milliliter: 'milliliter',
  drop: 'drop',
  cup: 'cup',
  tablespoon: 'tablespoon',
  teaspoon: 'teaspoon',
};

export const fractions = {
  188: '1/4',
  189: '1/2',
  190: '3/4',
  8531: '1/3',
  8532: '2/3',
  8533: '1/5',
  8534: '2/5',
  8535: '3/5',
  8536: '4/5',
  8537: '1/6',
  8538: '5/6',
  8539: '1/8',
  8540: '3/8',
  8541: '5/8',
  8542: '7/8',
};
