import { db } from '../firebase';
import {
  LOGIN,
  LOGOUT,
  NAVBAR,
  THEMENAME,
  ALLRECIPES,
  LOADING,
  LOADINGSMALL,
  RECIPE,
  RECIPEID,
  ALLCATEGORIES,
  REACHEDLAST,
  NUMBEROFITEMSONSHOPPINGLIST,
  ALERTMODALDATA,
  SHOPPINGLISTDATA,
  ADDTOSHOPPINGLISTDATA,
  RECIPETOADDLINK,
  UPDATINGFAVORITE,
  UPDATINGSHOPPING,
  RECIPEIDFROMURL,
} from './constants';

const initialState = {
  db,
  currentUser: null,
  navbar: false,
  themeName: 'light',
  allRecipes: [],
  loading: false,
  loadingSmall: false,
  recipe: {},
  recipeId: null,
  numberOfItemsOnShoppingList: 0,
  reachedLast: false,
  allCategories: [],
  alertModalData: {
    state: false,
    actionText: '',
    actionButtonMode: '',
    secondaryText: '',
    secondaryButtonMode: '',
    cancelText: '',
    cancelButtonMode: '',
    bodyText: '',
    handleFunction: () => {},
  },
  shoppingListData: [],
  AddToShoppingListData: {
    state: false,
    recipeData: {},
    previousScreen: '',
    completeActionFunction: () => {},
  },
  recipeToAddLink: '',
  updatingFavorite: false,
  updatingShopping: false,
  recipeIdFromUrl: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        currentUser: action.payload,
      };
    case LOGOUT:
      return initialState;
    case NAVBAR:
      return {
        ...state,
        navbar: action.payload,
      };
    case THEMENAME:
      return {
        ...state,
        themeName: action.payload,
      };
    case ALLRECIPES:
      return {
        ...state,
        allRecipes: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADINGSMALL:
      return {
        ...state,
        loadingSmall: action.payload,
      };
    case RECIPE:
      return {
        ...state,
        recipe: action.payload,
      };
    case RECIPEID:
      return {
        ...state,
        recipeId: action.payload,
      };
    case REACHEDLAST:
      return {
        ...state,
        reachedLast: action.payload,
      };
    case NUMBEROFITEMSONSHOPPINGLIST:
      return {
        ...state,
        numberOfItemsOnShoppingList: action.payload,
      };
    case ALLCATEGORIES:
      return {
        ...state,
        allCategories: action.payload,
      };
    case ALERTMODALDATA:
      return {
        ...state,
        alertModalData: action.payload,
      };
    case SHOPPINGLISTDATA:
      return {
        ...state,
        shoppingListData: action.payload,
      };
    case ADDTOSHOPPINGLISTDATA:
      return {
        ...state,
        AddToShoppingListData: action.payload,
      };
    case RECIPETOADDLINK:
      return {
        ...state,
        recipeToAddLink: action.payload,
      };
    case UPDATINGFAVORITE:
      return {
        ...state,
        updatingFavorite: action.payload,
      };
    case UPDATINGSHOPPING:
      return {
        ...state,
        updatingShopping: action.payload,
      };
    case RECIPEIDFROMURL:
      return {
        ...state,
        recipeIdFromUrl: action.payload,
      };
    default:
      return state;
  }
};
