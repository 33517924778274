import { useState } from 'react';
import Input from '@mui/material/Input';

import { addRecipe, updateRecipe, getManualItemsRecipe } from 'adapters/recipeAdapters';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetAllRecipes } from 'redux/actions';
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';

export default function AddToShoppingListInput() {
  const dispatch = useDispatch();
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);

  const [ingredientText, setIngredientText] = useState('');
  const [disabledInput, setDisabledInput] = useState(false);
  const updateShoppingList = useUpdateShoppingList();

  const handleItemInput = (event) => {
    setIngredientText(event.target.value);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      setDisabledInput(true);
      let docRef;
      const manualListFound = await getManualItemsRecipe({
        db,
        currentUserId: currentUser.uid,
      });
      const newItem = {
        purchased: false,
        string: ingredientText,
        toAdd: true,
      };
      const parsedAllRecipes = JSON.parse(JSON.stringify(allRecipes));

      if (!manualListFound) {
        newItem.index = 0;
        const manualRecipeObj = {
          title: 'Manual Items',
          ingredients: [
            {
              index: 0,
              ingredients: [newItem],
              groupName: 'standard',
            },
          ],
          onShoppingList: true,
          deleted: false,
          manualList: true,
          categories: [],
        };
        docRef = await addRecipe({
          db,
          currentUserId: currentUser.uid,
          payload: manualRecipeObj,
        });

        parsedAllRecipes.push(docRef);
      } else {
        const parsedManualList = JSON.parse(JSON.stringify(manualListFound));
        parsedManualList.onShoppingList = true;
        newItem.index = parsedManualList.ingredients[0].ingredients.length;
        parsedManualList.ingredients[0].ingredients.push(newItem);
        docRef = await updateRecipe({
          db,
          currentUserId: currentUser.uid,
          recipeId: parsedManualList.id,
          payload: parsedManualList,
        });

        const foundAllIndex = parsedAllRecipes.findIndex((x) => x.id === docRef.id);
        [parsedAllRecipes[foundAllIndex]] = [parsedManualList];
      }
      dispatch(SetAllRecipes(parsedAllRecipes));

      await updateShoppingList();

      setIngredientText('');
      setDisabledInput(false);
    }
  };

  return (
    <div className="w-full">
      <div
        className="pb-3 pl-3 pr-4 background-color rounded-3xl border-solid border-2 border-gray-200"
        style={{ boxShadow: 'none' }}
      >
        <Input
          className="mx-0.5 pt-3 w-full md:w-60"
          disabled={disabledInput}
          id="addItem"
          name="Add Item"
          placeholder="Add Item to List"
          type="text"
          autoComplete="off"
          value={ingredientText}
          onChange={handleItemInput}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}
