import PropTypes from 'prop-types';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import StarIcon from '@mui/icons-material/Star';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';

export default function IconButton({
  type,
  itemToUpdate,
  updating,
  handleFunction,
  tooltipText,
  tooltipPosition = 'bottom',
}) {
  const props = {
    fontSize: 'small',
    className: `${itemToUpdate ? `text-yellow-400` : 'text-white'} fill-current`,
  };
  return (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      disableInteractive
      placement={tooltipPosition}
      title={tooltipText}
    >
      <button
        className="uppercase px-4 py-2 text-xs bg-gray-500 text-blue-100 hover:bg-gray-500 duration-300 w-14 mx-1 h-9 mt-1 rounded-xl"
        type="button"
        onClick={handleFunction}
        name={type}
      >
        {updating ? (
          <AutorenewIcon fontSize="small" className="animate-spin" />
        ) : (
          <div>
            {type === 'favorite' && <StarIcon {...props} />}
            {type === 'onShoppingList' && <ShoppingCartIcon {...props} />}
            {type === 'delete' && <DeleteIcon {...props} />}
            {type === 'edit' && <Edit {...props} />}
          </div>
        )}
      </button>
    </Tooltip>
  );
}

IconButton.propTypes = {
  type: PropTypes.string.isRequired,
  itemToUpdate: PropTypes.bool,
  updating: PropTypes.bool,
  handleFunction: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

IconButton.defaultProps = {
  itemToUpdate: false,
  updating: false,
  tooltipText: '',
  tooltipPosition: 'bottom',
};
