/* eslint-disable prettier/prettier */
import {
  LOGIN,
  LOGOUT,
  NAVBAR,
  THEMENAME,
  ALLRECIPES,
  LOADING,
  LOADINGSMALL,
  REACHEDLAST,
  ALLCATEGORIES,
  RECIPE,
  RECIPEID,
  NUMBEROFITEMSONSHOPPINGLIST,
  ALERTMODALDATA,
  SHOPPINGLISTDATA,
  ADDTOSHOPPINGLISTDATA,
  RECIPETOADDLINK,
  UPDATINGFAVORITE,
  UPDATINGSHOPPING,
  RECIPEIDFROMURL,
} from './constants';

export const Login = (currentUser) => (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: currentUser,
  });
};

export const Logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: null,
  });
};
export const SetNavbar = (navbar) => (dispatch) => {
  dispatch({
    type: NAVBAR,
    payload: navbar,
  });
};

export const SetThemeName = (themeName) => (dispatch) => {
  dispatch({
    type: THEMENAME,
    payload: themeName,
  });
};

export const SetAllRecipes = (allRecipes) => (dispatch) => {
  dispatch({
    type: ALLRECIPES,
    payload: allRecipes,
  });
};

export const SetLoading = (loading) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: loading,
  });
};

export const SetLoadingSmall = (loadingSmall) => (dispatch) => {
  dispatch({
    type: LOADINGSMALL,
    payload: loadingSmall,
  });
};

export const SetRecipe = (recipe) => (dispatch) => {
  dispatch({
    type: RECIPE,
    payload: recipe,
  });
};

export const SetRecipeId = (recipeId) => (dispatch) => {
  dispatch({
    type: RECIPEID,
    payload: recipeId,
  });
};

export const SetNumberOfItemsOnShoppingList = (number) => (dispatch) => {
  dispatch({
    type: NUMBEROFITEMSONSHOPPINGLIST,
    payload: number,
  });
};

export const SetReachedLast = (boolean) => (dispatch) => {
  dispatch({
    type: REACHEDLAST,
    payload: boolean,
  });
};

export const SetAllCategories = (categories) => (dispatch) => {
  dispatch({
    type: ALLCATEGORIES,
    payload: categories,
  });
};

export const SetAlertModalData = (alertModalData) => (dispatch) => {
  dispatch({
    type: ALERTMODALDATA,
    payload: alertModalData,
  });
};

export const SetShoppingListData = (shoppingListData) => (dispatch) => {
  dispatch({
    type: SHOPPINGLISTDATA,
    payload: shoppingListData,
  });
};

export const SetAddToShoppingListData = (addShoppingListData) => (dispatch) => {
  dispatch({
    type: ADDTOSHOPPINGLISTDATA,
    payload: addShoppingListData,
  });
};

export const SetRecipeToAddLink = (recipeToAddLink) => (dispatch) => {
  dispatch({
    type: RECIPETOADDLINK,
    payload: recipeToAddLink,
  });
};
export const SetUpdatingFavorite = (updatingFavorite) => (dispatch) => {
  dispatch({
    type: UPDATINGFAVORITE,
    payload: updatingFavorite,
  });
};

export const SetUpdatingShopping = (updatingShopping) => (dispatch) => {
  dispatch({
    type: UPDATINGSHOPPING,
    payload: updatingShopping,
  });
};

export const SetRecipeIdFromUrl = (id) => (dispatch) => {
  dispatch({
    type: RECIPEIDFROMURL,
    payload: id,
  });
};
