// React
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// Adapters
// Internal
import Header from 'components/Header';
import ScrollToTop from 'components/ScrollToTop';
import LoadingOverlay from 'components/LoadingOverlay';
import AddToShoppingListModal from 'components/ShoppingList/AddToShoppingListModal';
import SupportBubble from 'components/SupportBubble';
import AlertModal from 'components/AlertModal';
import Home from 'pages/Home';
import Recipe from 'pages/Recipe';
import AddRecipe from 'pages/AddRecipe';
import EditRecipe from 'pages/EditRecipe';
import ShoppingList from 'pages/ShoppingList';
import Privacy from 'pages/Privacy';
// Ads
// import WebBannerAd from 'components/Ads/WebBannerAd';
// import WebMobileBannerAd from 'components/Ads/WebMobileBannerAd';
// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
// Styles
import 'tailwind.css';
import 'App.css';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetRecipe, SetRecipeId } from 'redux/actions';

export default function AppRouterHolder() {
  // redux
  const dispatch = useDispatch();
  const alertModalData = useSelector((rdxState) => rdxState.reducers.alertModalData);
  const navbar = useSelector((rdxState) => rdxState.reducers.navbar);
  const themeName = useSelector((rdxState) => rdxState.reducers.themeName);
  // Dimensions
  const { width } = useWindowDimensions();
  // Local state
  const [windowType, setWindowType] = useState('desktop');

  useEffect(() => {
    dispatch(SetRecipe({}));
    dispatch(SetRecipeId(null));
  }, []);

  useEffect(() => {
    if (width < 768) {
      setWindowType('mobile');
    } else {
      setWindowType('desktop');
    }
  }, [width]);

  return (
    <div id="top" className={`${themeName} app`}>
      <Router>
        <LoadingOverlay />
        <AddToShoppingListModal />
        <AlertModal {...alertModalData} />
        <div className="flex flex-col h-screen">
          <Header />
          <div className="flex-1 overflow-y-auto py-5 px-3 lg:px-5">
            <div style={{ display: navbar ? 'none' : 'block container' }} />
            <div className="mb-20 mx-0 lg:mx-6 xl:mx-52">
              <Routes>
                <Route path="/recipes" element={<Home windowType={windowType} />} />
                <Route
                  exact
                  path="/recipe/:recipeId"
                  element={<Recipe windowType={windowType} />}
                />
                <Route exact path="/addrecipe" element={<AddRecipe windowType={windowType} />} />
                <Route exact path="/editrecipe" element={<EditRecipe windowType={windowType} />} />
                <Route
                  exact
                  path="/shoppinglist"
                  element={<ShoppingList windowType={windowType} />}
                />
                <Route exact path="/privacy" element={<Privacy windowType={windowType} />} />
                <Route path="*" element={<Navigate to="/recipes" replace />} />
              </Routes>
              <SupportBubble />
              <ScrollToTop />
            </div>
          </div>
          {/* {width > 728 ? <WebBannerAd /> : <WebMobileBannerAd />} */}
        </div>
      </Router>
    </div>
  );
}
