import { useState, useEffect } from 'react';
import { random } from 'lodash';
import moment from 'moment';
// Cotext
import largeImagesData from 'contexts/homeBgs';
import logoData from 'contexts/logoData';
import { signInWithGoogle } from 'contexts/auth/authFunctions';
// Components
import LoadingOverlay from 'components/LoadingOverlay';
import AlertModal from 'components/AlertModal';
// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
// Redux
import { useDispatch } from 'react-redux';
import { Login, SetLoading, SetAlertModalData } from 'redux/actions';
// Badges
// import appStoreBadge from 'assets/mobileBadges/appStoreBadge.png';
// import googlePlayBadge from 'assets/mobileBadges/googlePlayBadge.png';
// Styles
import './SignIn.css';

export default function SignIn() {
  const dispatch = useDispatch();
  const currentYear = moment().format('YYYY');

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLink, setImageLink] = useState(largeImagesData.desktop[0]);
  const [currentWidth, setCurrentWidth] = useState(0);
  const { width } = useWindowDimensions();

  const storeCurrentUser = (userData) => {
    if (userData) {
      return dispatch(Login(JSON.parse(JSON.stringify(userData))));
    }
    return dispatch(Login({}));
  };

  const handleSignInWithGoogle = async () => {
    const userFoundAndActive = await signInWithGoogle();
    if (userFoundAndActive) {
      storeCurrentUser(userFoundAndActive);
    } else {
      storeCurrentUser({});
      dispatch(
        SetAlertModalData({
          state: true,
          cancelText: 'close',
          cancelButtonMode: 'alert',
          bodyText:
            'The user has been deactivated. If you want to restore it, please email: support@shopreceta.com with the account email and reason for re-activation.',
          handleFunction: () => {},
        }),
      );
    }
  };

  const setRandomPhoto = () => {
    const randomNumber = random(0, largeImagesData.desktop.length - 1);
    const imgLink = largeImagesData.desktop[randomNumber];
    setImageLink(imgLink);
  };

  const onImageLoad = async () => {
    dispatch(SetLoading(false));
    setImageLoaded(true);
  };

  useEffect(() => {
    setRandomPhoto();
  }, []);

  useEffect(() => {
    if (currentWidth !== width) {
      setCurrentWidth(width);
    }
  }, [width]);

  return (
    <div className="flex h-screen overflow-hidden">
      <AlertModal />
      <LoadingOverlay />
      <div className="flex sepia">
        <div className={`z-0 ${imageLoaded ? 'showImage' : 'hiddenImage'}`} id="bgImage">
          <img
            id="imgImg"
            src={imageLink}
            alt="food"
            className="w-full object-cover min-h-full"
            onLoad={onImageLoad}
          />
          {imageLoaded && (
            <div className="absolute inset-0 flex justify-center items-center z-10">
              <div>
                <div className="flex justify-center md:mb-10">
                  <img
                    id="logo"
                    src={logoData.logoStacked}
                    alt="logo"
                    className=" w-max sm:w-5/12 md:w-6/12 lg:w-1/2 px-5 md:px-0"
                  />
                </div>
                <div className="flex justify-center items-center">
                  <button
                    className="login-with-google-btn"
                    type="button"
                    onClick={handleSignInWithGoogle}
                  >
                    Sign in with Google
                  </button>
                </div>
                {/* <div className="flex justify-center items-center">
                  <div className="sm:flex">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.recipes.shopreceta"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="h-auto w-36 md:w-52">
                        <img src={googlePlayBadge} alt="Google Play" />
                      </div>
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/shopreceta/id1673157737"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="h-auto w-36 md:w-52">
                        <img src={appStoreBadge} alt="App Store" />
                      </div>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
        <div className="w-full absolute bottom-0">
          <div
            className={`flex justify-center w-full h-8 bg-gradient-to-b from-transparent to-black ${
              imageLoaded ? 'showImage' : 'hiddenImage'
            }`}
          >
            <div className="text-white text-center text-sm absolute bottom-1">
              © {currentYear} ShopReceta. All rights reserved. <a href="/privacy">Privacy policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
