import logoHorizontal from 'assets/logo/logoHorizontal.png';
import logoStacked from 'assets/logo/logoPhraseStacked.png';
import logoOnly from 'assets/logo/logoOnly.png';

const logoData = {
  logoHorizontal,
  logoStacked,
  logoOnly,
};

export default logoData;
