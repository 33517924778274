import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { updateRecipe, getRecipeById } from 'adapters/recipeAdapters';
import ShoppingListByRecipe from 'components/ShoppingList/ShoppingListByRecipe';
import ShoppingListByGroup from 'components/ShoppingList/ShoppingListByGroup';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetLoading, SetAllRecipes, SetRecipeId, SetRecipe } from 'redux/actions';
// Hooks
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';
// Icons
import SyncAltIcon from '@mui/icons-material/SyncAlt';
// Styles
import './ShoppingListHolder.css';

export default function ShoppingListHolder({ getShoppingListRecipes }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateShoppingList = useUpdateShoppingList();

  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const shoppingListData = useSelector((rdxState) => rdxState.reducers.shoppingListData);

  const [viewByRecipe, setViewByRecipe] = useState(false);
  const [recipesNames, setRecipesNames] = useState([]);
  const [rotate, setRotate] = useState(false);

  const defineRecipeNames = () => {
    const recipesNamesTemp = [];
    const sortedArr = shoppingListData.reduce((acc, element) => {
      if (element.manualList) {
        return [element, ...acc];
      }
      return [...acc, element];
    }, []);
    sortedArr.forEach((recipe) => {
      recipesNamesTemp.push({ recipeTitle: recipe.title, id: recipe.id });
    });
    setRecipesNames([...recipesNamesTemp]);
  };

  const removeRecipeFromShoppingList = async (data) => {
    dispatch(SetLoading(true));
    const recipeFoundIndex = shoppingListData.findIndex((element) => element.id === data.id);
    const parsedRecipeFound = JSON.parse(JSON.stringify(shoppingListData[recipeFoundIndex]));
    if (parsedRecipeFound.manualList) {
      parsedRecipeFound.ingredients[0].ingredients = [];
    } else {
      parsedRecipeFound.ingredients.forEach((ingredientsGroup) => {
        ingredientsGroup.ingredients.forEach((ingredient) => {
          ingredient.purchased = false;
        });
      });
    }

    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: data.id,
      payload: {
        ingredients: parsedRecipeFound.ingredients,
        onShoppingList: false,
      },
    });
    // update Shopping List
    await updateShoppingList();
    // update in allRecipes
    const recipeFoundInAllRecipesIndex = allRecipes.findIndex((element) => element.id === data.id);
    const parsedAllRecipes = JSON.parse(JSON.stringify(allRecipes));
    parsedAllRecipes[recipeFoundInAllRecipesIndex] = updatedRecipe;
    dispatch(SetAllRecipes(parsedAllRecipes));
    dispatch(SetLoading(false));
  };

  const goToRecipe = async (data) => {
    dispatch(SetLoading(true));
    const recipeById = await getRecipeById({
      db,
      currentUserId: currentUser.uid,
      payload: { id: data.id },
    });
    dispatch(SetLoading(false));
    dispatch(SetRecipe(recipeById));
    dispatch(SetRecipeId(recipeById.id));
    navigate(`/recipe/${recipeById.id}`);
  };

  const handleChangeView = () => {
    setViewByRecipe(!viewByRecipe);
    setRotate(!rotate);
  };

  useEffect(() => {
    defineRecipeNames();
  }, [shoppingListData]);

  return (
    <div className="flex-auto justify-center border-t-2">
      <div className="mb-5">
        <div className="">
          <div className="grow md:grow-0 lg:flex">
            <div className="mx-4 md:mx-5 my-4 lg:w-1/4">
              {shoppingListData.length > 0 && (
                <div className="text-2xl ml-2">
                  {`${shoppingListData.length} recipe${shoppingListData.length !== 1 ? 's' : ''}`}
                </div>
              )}
              {recipesNames.length > 0 && (
                <div className="flex-grow">
                  {recipesNames.map((rec, idx) => (
                    <div
                      key={rec.id}
                      className="flex justify-between center-align hover:bg-gray-200 w-full m-h-10 p-2"
                    >
                      <button
                        type="button"
                        className="w-10/12 my-1 ml-1 text-left"
                        onClick={() => goToRecipe({ id: rec.id })}
                      >
                        {rec.recipeTitle}
                      </button>
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <CloseIcon
                            type="button"
                            className="w-1/12 cursor-pointer mr-5"
                            onClick={() => removeRecipeFromShoppingList({ id: rec.id, idx })}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex mb-3 md:mt-0 lg:w-3/4 bg-gray-50">
              <div className="lg:flex flex-row h-5/6 md:h-4/5 w-full">
                <div className="mt-8 w-full lg:mt-0 border border-gray-200 border-t-0 border-l-1 border-r-0 border-b-0">
                  <div className="mt-3 ml-3">
                    <button className="flex items-center" type="button" onClick={handleChangeView}>
                      <div className={`mt-1 mr-1 ${rotate ? 'fa-arrow-down' : 'fa-arrow-up'}`}>
                        <SyncAltIcon />
                      </div>
                      <div className="text-2xl mt-1 capitalize italic">
                        View By {viewByRecipe ? 'Recipe' : 'Group'}
                      </div>
                    </button>
                  </div>
                  {viewByRecipe && (
                    <div>
                      {shoppingListData.map((recipe) => (
                        <ShoppingListByRecipe
                          key={recipe.id}
                          recipeId={recipe.id}
                          recipeData={recipe}
                          getShoppingListRecipes={getShoppingListRecipes}
                        />
                      ))}
                    </div>
                  )}
                  {!viewByRecipe && (
                    <ShoppingListByGroup
                      shoppingListData={shoppingListData}
                      getShoppingListRecipes={getShoppingListRecipes}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ShoppingListHolder.propTypes = {
  getShoppingListRecipes: PropTypes.func.isRequired,
};
