const foodsCollection = [
  { name: 'green bean', shoppingGroupId: 37 },
  { name: 'aluminum foil', shoppingGroupId: 35 },
  { name: 'peanut butter', shoppingGroupId: 24 },
  { name: 'toilet paper', shoppingGroupId: 30 },
  { name: 'oil', shoppingGroupId: 29 },
  { name: 'olive oil', shoppingGroupId: 29 },
  { name: 'vinegar', shoppingGroupId: 29 },
  { name: 'balsamic vinegar', shoppingGroupId: 29 },
  { name: 'basil', shoppingGroupId: 22 },
  { name: 'sugar', shoppingGroupId: 11 },
  { name: 'beer', shoppingGroupId: 3 },
  { name: 'ale', shoppingGroupId: 3 },
  { name: 'onion', shoppingGroupId: 37 },
  { name: 'onion powder', shoppingGroupId: 36 },
  { name: 'buttermilk', shoppingGroupId: 13 },
  { name: 'half and half', shoppingGroupId: 13 },
  { name: 'half & half', shoppingGroupId: 13 },
  { name: 'milk', shoppingGroupId: 13 },
  { name: 'heavy cream', shoppingGroupId: 13 },
  { name: 'butter', shoppingGroupId: 13 },
  { name: 'ghee', shoppingGroupId: 13 },
  { name: 'cheese', shoppingGroupId: 13 },
  { name: '4-cheese', shoppingGroupId: 13 },
  { name: 'mozzarella', shoppingGroupId: 13 },
  { name: 'parmesan', shoppingGroupId: 13 },
  { name: 'gorgonzola', shoppingGroupId: 13 },
  { name: 'manchego', shoppingGroupId: 13 },
  { name: 'sauce', shoppingGroupId: 11 },
  { name: 'coconut amino', shoppingGroupId: 11 },
  { name: 'soy sauce', shoppingGroupId: 11 },
  { name: 'chile', shoppingGroupId: 37 },
  { name: 'dry chile', shoppingGroupId: 36 },
  { name: 'dried oregano', shoppingGroupId: 36 },
  { name: 'oregano', shoppingGroupId: 36 },
  { name: 'paprika', shoppingGroupId: 36 },
  { name: 'bay leaves', shoppingGroupId: 36 },
  { name: 'bay leaf', shoppingGroupId: 36 },
  { name: 'fennel seed', shoppingGroupId: 36 },
  { name: 'noodles', shoppingGroupId: 31 },
  { name: 'caper', shoppingGroupId: 11 },
  { name: 'shrimp', shoppingGroupId: 33 },
  { name: 'pasta', shoppingGroupId: 31 },
  { name: 'fettuccine', shoppingGroupId: 31 },
  { name: 'spaghetti', shoppingGroupId: 31 },
  { name: 'rigatoni', shoppingGroupId: 31 },
  { name: 'salmon', shoppingGroupId: 33 },
  { name: 'squid', shoppingGroupId: 33 },
  { name: 'trout', shoppingGroupId: 33 },
  { name: 'mahi', shoppingGroupId: 33 },
  { name: 'Cointreau Liqueur', shoppingGroupId: 39 },
  { name: 'lime juice', shoppingGroupId: 25 },
  { name: 'lime', shoppingGroupId: 37 },
  { name: 'lemon', shoppingGroupId: 37 },
  { name: 'brussels sprouts', shoppingGroupId: 37 },
  { name: 'yerba buena', shoppingGroupId: 37 },
  { name: 'lemon juice', shoppingGroupId: 25 },
  { name: 'ginger', shoppingGroupId: 37 },
  { name: 'beet', shoppingGroupId: 37 },
  { name: 'water', shoppingGroupId: 4 },
  { name: 'tofu', shoppingGroupId: 23 },
  { name: 'udon', shoppingGroupId: 23 },
  { name: 'mirin', shoppingGroupId: 23 },
  { name: 'soyrizo', shoppingGroupId: 23 },
  { name: 'bouillon', shoppingGroupId: 36 },
  { name: 'carrot', shoppingGroupId: 37 },
  { name: 'pickled carrot', shoppingGroupId: 7 },
  { name: 'steak', shoppingGroupId: 27 },
  { name: 'ground beef', shoppingGroupId: 27 },
  { name: 'chuck roast', shoppingGroupId: 27 },
  { name: 'sausage', shoppingGroupId: 27 },
  { name: 'beef', shoppingGroupId: 27 },
  { name: 'pork', shoppingGroupId: 27 },
  { name: 'bacon', shoppingGroupId: 27 },
  { name: 'pancetta', shoppingGroupId: 27 },
  { name: 'chicken broth', shoppingGroupId: 34 },
  { name: 'chicken', shoppingGroupId: 27 },
  { name: 'garlic', shoppingGroupId: 37 },
  { name: 'sesame seed', shoppingGroupId: 11 },
  { name: 'black pepper', shoppingGroupId: 36 },
  { name: 'thyme', shoppingGroupId: 36 },
  { name: 'ground', shoppingGroupId: 36 },
  { name: 'ground cinnamon', shoppingGroupId: 36 },
  { name: 'dried coriander', shoppingGroupId: 36 },
  { name: 'pine nut', shoppingGroupId: 28 },
  { name: 'nutmeg', shoppingGroupId: 36 },
  { name: 'egg', shoppingGroupId: 27 },
  { name: 'ham', shoppingGroupId: 27 },
  { name: 'mango', shoppingGroupId: 15 },
  { name: 'cilantro', shoppingGroupId: 37 },
  { name: 'spinach', shoppingGroupId: 37 },
  { name: 'choi', shoppingGroupId: 37 },
  { name: 'jicama', shoppingGroupId: 37 },
  { name: 'broccoli', shoppingGroupId: 37 },
  { name: 'celery', shoppingGroupId: 37 },
  { name: 'radish', shoppingGroupId: 37 },
  { name: 'mushroom', shoppingGroupId: 37 },
  { name: 'tomatoes', shoppingGroupId: 37 },
  { name: 'lime', shoppingGroupId: 15 },
  { name: 'orange', shoppingGroupId: 15 },
  { name: 'tomato', shoppingGroupId: 37 },
  { name: 'corn', shoppingGroupId: 37 },
  { name: 'arugula', shoppingGroupId: 37 },
  { name: 'avocado', shoppingGroupId: 37 },
  { name: 'cucumber', shoppingGroupId: 37 },
  { name: 'lettuce', shoppingGroupId: 37 },
  { name: 'cavolo nero', shoppingGroupId: 37 },
  { name: 'chive', shoppingGroupId: 37 },
  { name: 'parsley', shoppingGroupId: 37 },
  { name: 'asparagus', shoppingGroupId: 37 },
  { name: 'shallot', shoppingGroupId: 37 },
  { name: 'zucchini', shoppingGroupId: 37 },
  { name: 'peas', shoppingGroupId: 37 },
  { name: 'kale', shoppingGroupId: 37 },
  { name: 'cauliflower', shoppingGroupId: 37 },
  { name: 'scallion', shoppingGroupId: 37 },
  { name: 'dates', shoppingGroupId: 15 },
  { name: 'rice', shoppingGroupId: 2 },
  { name: 'lentil', shoppingGroupId: 2 },
  { name: 'seasoning salute', shoppingGroupId: 36 },
  { name: 'ground cumin', shoppingGroupId: 36 },
  { name: 'cumin powder', shoppingGroupId: 36 },
  { name: 'chipotle cube', shoppingGroupId: 36 },
  { name: 'chili', shoppingGroupId: 36 },
  { name: 'jalapeños', shoppingGroupId: 36 },
  { name: 'ground coriander', shoppingGroupId: 36 },
  { name: 'chili powder', shoppingGroupId: 36 },
  { name: 'ground turmeric', shoppingGroupId: 36 },
  { name: 'garam masala', shoppingGroupId: 36 },
  { name: 'dried thyme', shoppingGroupId: 36 },
  { name: 'maple syrup', shoppingGroupId: 11 },
  { name: 'seasoning', shoppingGroupId: 11 },
  { name: 'tahini', shoppingGroupId: 11 },
  { name: 'raisins', shoppingGroupId: 14 },
  { name: 'fresh rosemary', shoppingGroupId: 16 },
  { name: 'rosemary', shoppingGroupId: 36 },
  { name: 'honey', shoppingGroupId: 24 },
  { name: 'sriracha', shoppingGroupId: 11 },
  { name: 'ketchup', shoppingGroupId: 11 },
  { name: 'catsup', shoppingGroupId: 11 },
  { name: 'mustard', shoppingGroupId: 11 },
  { name: 'kidney beans', shoppingGroupId: 2 },
  { name: 'black beans', shoppingGroupId: 2 },
  { name: 'beans', shoppingGroupId: 2 },
  { name: 'wine', shoppingGroupId: 39 },
  { name: 'stock', shoppingGroupId: 7 },
  { name: 'sour cream', shoppingGroupId: 13 },
  { name: 'bread', shoppingGroupId: 5 },
  { name: 'apple', shoppingGroupId: 15 },
  { name: 'potato', shoppingGroupId: 37 },
  { name: 'curry paste', shoppingGroupId: 23 },
  { name: 'turkey', shoppingGroupId: 27 },
  { name: 'italian seasoning', shoppingGroupId: 36 },
  { name: 'toothpick', shoppingGroupId: 30 },
  { name: 'coffee', shoppingGroupId: 10 },
  { name: 'napkin', shoppingGroupId: 30 },
  { name: 'towel', shoppingGroupId: 30 },
  { name: 'plate', shoppingGroupId: 30 },
  { name: 'paper towel', shoppingGroupId: 30 },
  { name: 'paper plate', shoppingGroupId: 30 },
  { name: 'broth', shoppingGroupId: 34 },
  { name: 'beef broth', shoppingGroupId: 34 },
  { name: 'vegetable broth', shoppingGroupId: 34 },
  { name: 'can', shoppingGroupId: 7 },
  { name: 'canned', shoppingGroupId: 7 },
  { name: 'salsa', shoppingGroupId: 11 },
  { name: 'taco seasoning', shoppingGroupId: 23 },
  { name: 'taco shell', shoppingGroupId: 23 },
  { name: 'olives', shoppingGroupId: 11 },
  { name: 'cashew', shoppingGroupId: 28 },
  { name: 'flour', shoppingGroupId: 1 },
  { name: 'corn starch', shoppingGroupId: 1 },
  { name: 'dish soap', shoppingGroupId: 9 },
  { name: 'feta', shoppingGroupId: 8 },
  { name: 'dill', shoppingGroupId: 22 },
  { name: 'mint', shoppingGroupId: 22 },
  { name: 'meat', shoppingGroupId: 27 },
  { name: 'chorizo', shoppingGroupId: 27 },
  { name: 'chips', shoppingGroupId: 12 },
  { name: 'ice cream', shoppingGroupId: 12 },
  { name: 'pepper', shoppingGroupId: 36 },
  { name: 'salt', shoppingGroupId: 36 },
  { name: 'coconut', shoppingGroupId: 15 },
  { name: 'nuts', shoppingGroupId: 28 },
];

export default foodsCollection;
