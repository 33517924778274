import { getRecipesByQuery } from 'adapters/recipeAdapters';
import { useSelector, useDispatch } from 'react-redux';
import { SetShoppingListData, SetNumberOfItemsOnShoppingList } from 'redux/actions';
import addItemsToShoppingListTotal from 'helpers/addItemsToShoppingListTotal';

export default function useUpdateShoppingList() {
  const dispatch = useDispatch();
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);

  const handleShoppingListDataUpdate = async () => {
    const shoppingList = await getRecipesByQuery({
      db,
      currentUserId: currentUser.uid,
      payload: {
        key: 'onShoppingList',
        where: '==',
        value: true,
      },
    });

    dispatch(SetShoppingListData(shoppingList));
    const numberOfItemsOnShoppingList = addItemsToShoppingListTotal(shoppingList);
    dispatch(SetNumberOfItemsOnShoppingList(numberOfItemsOnShoppingList));
  };

  return handleShoppingListDataUpdate;
}
