import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
// Redux
import { useSelector } from 'react-redux';
// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

export default function SupportBubble() {
  const themeName = useSelector((rdxState) => rdxState.reducers.themeName);
  // Dimensions
  const { width } = useWindowDimensions();
  const sendEmail = () => {
    window.location = 'mailto:support@shopreceta.com?subject=Contact ShopReceta Support';
  };
  return (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      disableInteractive
      placement="top"
      title="Contact Support"
    >
      <button
        type="button"
        className={`rounded-full flex items-center justify-center fixed border-solid border-gray-200 border-2 ${
          width > 728 ? `bottom-24  right-4` : 'bottom-14  right-4'
        }`}
        style={{
          height: '45px',
          width: '45px',
          backgroundColor: themeName === 'dark' ? '#90a0d9' : '#357C3C',
        }}
        onClick={sendEmail}
      >
        <ContactSupportIcon style={{ fill: 'white' }} />
      </button>
    </Tooltip>
  );
}
