import { useState, useEffect } from 'react';
import { Typography, Paper, Box, Grid, TextField } from '@mui/material';
import RecipeForm from 'components/Recipe/RecipeForm';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { forEach } from 'lodash';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetAlertModalData, SetLoading, SetRecipeToAddLink, SetRecipe } from 'redux/actions';
// Assets
import chromeExtensionBadge from 'assets/mobileBadges/chromeExtensionBadge.png';
import firefoxAddOnBadge from 'assets/mobileBadges/firefoxAddOnBadge.png';

export default function AddRecipe() {
  const dispatch = useDispatch();
  const recipeToAddLink = useSelector((rdxState) => rdxState.reducers.recipeToAddLink);

  const [type, setType] = useState('new');
  const [urlToScrape, setUrlToScrape] = useState('');
  const [text, setText] = useState('');

  const getCharCodes = (s) => {
    const charCodeArr = [];
    forEach(s, (letter, index) => {
      const code = s.charCodeAt(index);
      charCodeArr.push(code);
    });

    return charCodeArr.toString();
  };

  const getRecipeData = (e) => {
    let url;
    try {
      url = new URL(e);
      setTimeout(() => {
        dispatch(SetLoading(true));
      }, 100);
      setUrlToScrape(url.href);

      const keyString = `${url.href}${url.protocol}${url.pathname}`;

      const keyAscii = getCharCodes(keyString);
      const cipherText = CryptoJS.AES.encrypt(
        keyAscii,
        process.env.REACT_APP_CYPHER_KEY,
      ).toString();

      let fullURLtoScrape = '';
      if (process.env.NODE_ENV === 'development') {
        fullURLtoScrape = `http://localhost:9090/api/scraper`;
      } else {
        fullURLtoScrape = `${process.env.REACT_APP_SERVER_URL}/api/scraper`;
      }
      try {
        dispatch(SetLoading(true));
        axios
          .post(fullURLtoScrape, { url: url.href.toString(), key: cipherText.toString() })
          .then((res) => {
            if (res.data.title.length > 0) {
              setType('newLink');
              dispatch(SetRecipe(res.data));
            } else {
              dispatch(
                SetAlertModalData({
                  state: true,
                  cancelText: 'Close',
                  bodyText:
                    "Sorry! We can't seem to find the recipe information on this website. Please try adding the recipe manually.",
                }),
              );
            }
            dispatch(SetLoading(false));
          })
          .catch((error) => {
            console.log(
              '%c +++ AddRecipe - error',
              'color: orange; font-weight: bold',
              JSON.stringify(error, null, 4),
            );
            dispatch(SetLoading(false));
          });
      } catch (_) {
        dispatch(SetLoading(false));
      }
    } catch (error) {
      console.log('%c +++  AddRecipe - error: ', 'color: orange; font-weight: bold', error);
      dispatch(SetLoading(false));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      getRecipeData(text);
    }
  };

  useEffect(() => {
    if (recipeToAddLink.length) {
      setText(recipeToAddLink);
      setUrlToScrape(recipeToAddLink);
      getRecipeData(recipeToAddLink);
      dispatch(SetRecipeToAddLink(''));
    }
  }, [recipeToAddLink]);

  return (
    <div className="fade-in">
      <Paper>
        <Box px={3} pt={2}>
          <Typography variant="h6" align="center" margin="dense">
            Add recipe with a link
          </Typography>
          <Box py={2}>
            <Grid item xs={12} sm={12}>
              <div className="text-xs pl-1 pb-1">
                We will do our best to retrieve as much information from the recipe as we are able.
                You may need to add some info and/or edit it afterwards.
              </div>
              <TextField
                autoFocus
                autoComplete="off"
                fullWidth
                id="addUrl"
                value={text}
                label="Paste URL here (Include http:// or https://)"
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </Grid>
            <div className="md:flex w-full justify-end items-center">
              <div className="mt-3">
                <div className="text-xs pl-1 pb-1">
                  Add Recipes faster with these browser extensions:
                </div>
                <div className="flex items-center justify-center">
                  <div className="w-32 px-1 h-auto">
                    <a
                      href="https://addons.mozilla.org/en-US/firefox/addon/shopreceta/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={firefoxAddOnBadge} alt="Firefox Add-On" />
                    </a>
                  </div>
                  <div className="w-44 px-1 h-auto">
                    <a
                      href="https://chrome.google.com/webstore/detail/shopreceta/pgackhnbjcpmfagbfeanmpjemiaacfne?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={chromeExtensionBadge} alt="Chrome Extension" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Paper>
      <RecipeForm type={type} urlToScrape={urlToScrape} />
    </div>
  );
}
