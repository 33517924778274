// Firebase
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { getUserData } from 'adapters/userAdapters';

import firebaseApp, { db } from '../../firebase';

export const IsLoggedIn = () => {
  const auth = getAuth(firebaseApp);
  return useAuthState(auth);
};

const checkUserDeactivated = async (data) => getUserData({ db, currentUserId: data.uid });

export const signInWithGoogle = async () => {
  const auth = getAuth(firebaseApp);
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);

  if (result.user) {
    const userData = await checkUserDeactivated(result.user);

    if (!userData.deactivated) {
      const dataToUpdate = {
        email: result.user.email,
        deactivated: false,
        lastLogin: Date.now(),
      };
      if (!userData.dateCreated) {
        dataToUpdate.dateCreated = Date.now();
      }

      await updateDoc(doc(db, 'users', result.user.uid), dataToUpdate);
      return result.user;
    }
    return false;
  }

  return false;
};

export const signOut = async () => {
  const auth = await getAuth(firebaseApp);
  await auth.signOut();
  return true;
};
