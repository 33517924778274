import image0 from 'assets/foodBgs/0.jpg';
import image1 from 'assets/foodBgs/1.jpg';
import image2 from 'assets/foodBgs/2.jpg';
import image3 from 'assets/foodBgs/3.jpg';
import image4 from 'assets/foodBgs/4.jpg';
import image5 from 'assets/foodBgs/5.jpg';
import image6 from 'assets/foodBgs/6.jpg';
import image7 from 'assets/foodBgs/7.jpg';
import image8 from 'assets/foodBgs/8.jpg';
import image9 from 'assets/foodBgs/9.jpg';
import image10 from 'assets/foodBgs/10.jpg';
import image11 from 'assets/foodBgs/11.jpg';
import image12 from 'assets/foodBgs/12.jpg';
import image13 from 'assets/foodBgs/13.jpg';
import image14 from 'assets/foodBgs/14.jpg';
import image15 from 'assets/foodBgs/15.jpg';
import image16 from 'assets/foodBgs/16.jpg';
import image17 from 'assets/foodBgs/17.jpg';
import image18 from 'assets/foodBgs/18.jpg';
import image19 from 'assets/foodBgs/19.jpg';

const largeImagesData = {
  desktop: [
    image0,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
  ],
};

export default largeImagesData;
