import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import RecipeInfo from 'components/Recipe/RecipeInfo';
import RecipeOptions from 'components/Recipe/RecipeOptions';
import RecipeIngredients from 'components/Recipe/RecipeIngredients';
import RecipeInstructions from 'components/Recipe/RecipeInstructions';
import { getRecipeById } from 'adapters/recipeAdapters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { SetRecipe, SetRecipeIdFromUrl } from 'redux/actions';

export default function Recipe() {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const recipe = useSelector((rdxState) => rdxState.reducers.recipe);
  const recipeIdFromUrl = useSelector((rdxState) => rdxState.reducers.recipeIdFromUrl);

  const [showInfoAndOptions, setShowInfoAndOptions] = useState(true);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const getSingleRecipe = async (id) => {
    if (state?.recipeData) {
      dispatch(SetRecipe(state.recipeData));
      return;
    }

    const recipeById = await getRecipeById({
      db,
      currentUserId: currentUser.uid,
      payload: { id: id || recipeId },
    });

    if (!recipeById) {
      navigate('/recipes');
    }
    recipeById.id = recipeId;
    await dispatch(SetRecipe(recipeById));
  };

  useEffect(() => {
    if (recipeIdFromUrl?.length) {
      getSingleRecipe(recipeIdFromUrl);
      dispatch(SetRecipeIdFromUrl(''));
    }
  }, [recipeIdFromUrl]);

  useEffect(() => {
    if (!recipeId && allRecipes?.length === 0) {
      navigate('/recipes');
    }
  }, [recipe, recipeId]);

  useEffect(() => {
    if (currentUser.uid && recipeId) {
      getSingleRecipe();
    }
  }, [currentUser]);

  const hideInfoAndOptions = () => {
    setShowInfoAndOptions(!showInfoAndOptions);
  };

  return (
    <div className="fade-in">
      {!isEmpty(recipe) && (
        <div>
          {showInfoAndOptions ? (
            <div>
              <RecipeInfo hideInfoAndOptions={hideInfoAndOptions} />
              <RecipeOptions />
              <hr className="mt-3" />
            </div>
          ) : (
            <div className="flex justify-end">
              <div className="absolute flex justify-end">
                <button type="button" onClick={hideInfoAndOptions}>
                  <KeyboardArrowDownIcon />
                </button>
              </div>
            </div>
          )}

          <div className="lg:flex flex-row">
            <div className="lg:w-3/12 pr-6">
              <RecipeIngredients />
            </div>
            <div className="lg:w-9/12">{recipe.instructions && <RecipeInstructions />}</div>
          </div>
        </div>
      )}
    </div>
  );
}
