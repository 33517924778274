import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
// import forEach from 'lodash/forEach';
import debounce from 'lodash/debounce';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import random from 'lodash/random';
import { getMoreRecipes, getAllRecipes } from 'adapters/recipeAdapters';
// Adapters
import { getAllCategories } from 'adapters/categoriesAdapters';
// Components
import RecipeListItem from 'components/Recipes/RecipeListItem';
import IconButton from 'components/IconButton';
import TextButton from 'components/TextButton';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  SetLoading,
  SetAllRecipes,
  SetReachedLast,
  SetRecipeId,
  SetRecipe,
  SetAllCategories,
} from 'redux/actions';
// Hooks
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';

export default function RecipesListHolder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateShoppingList = useUpdateShoppingList();

  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const loading = useSelector((rdxState) => rdxState.reducers.loading);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);
  const reachedLast = useSelector((rdxState) => rdxState.reducers.reachedLast);
  const allCategories = useSelector((rdxState) => rdxState.reducers.allCategories);

  const [listRecipes, setListRecipes] = useState([]);
  const [categorySelected, setCategorySelected] = useState('none');
  const [sort, setSort] = useState('asc');
  const [filterText, setFilterText] = useState('');
  const [favoriteSelected, setFavoriteSelected] = useState(false);

  const sortAllRecipes = (listToSet) => {
    dispatch(SetAllRecipes(orderBy(listToSet, [(data) => data.title.toLowerCase()], sort)));
  };

  const updateList = () => {
    if (!filterText && categorySelected === 'none' && !favoriteSelected) {
      return sortAllRecipes(allRecipes);
    }

    let recipesToFilter = [];
    if (categorySelected !== 'none') {
      allRecipes.forEach((recipe) => {
        if (recipe.categories.length > 0) {
          if (recipe.categories.includes(categorySelected)) {
            recipesToFilter.push(recipe);
          }
        }
      });
    } else {
      recipesToFilter = allRecipes;
    }

    if (favoriteSelected) {
      recipesToFilter = recipesToFilter.filter((recipe) => recipe.favorite);
    }

    recipesToFilter = recipesToFilter.filter(
      (recipe) => recipe.title.toLowerCase().search(filterText.toLowerCase()) !== -1,
    );

    return setListRecipes(orderBy(recipesToFilter, [(data) => data.title.toLowerCase()], sort));
  };

  const handleCategoryChange = async (event) => {
    setCategorySelected(event.target.value);
  };

  const handleFilterListChange = (event) => {
    setFilterText(event.target.value);
  };
  // const handleSortChange = () => {
  //   if (sort === 'asc') {
  //     setSort('desc');
  //   } else {
  //     setSort('asc');
  //   }
  // };

  const handleFavoriteSelected = () => {
    setFavoriteSelected(!favoriteSelected);
  };

  const reset = () => {
    setCategorySelected('none');
    setFilterText('');
    setFavoriteSelected(false);
    setSort('asc');
  };

  const getRecipesToFilter = async (bypass) => {
    let recipesToFilter;
    if (reachedLast === true || bypass === true) {
      recipesToFilter = await getAllRecipes({ db, currentUserId: currentUser.uid });
      dispatch(SetReachedLast(true));
      dispatch(SetAllRecipes(recipesToFilter));
      return recipesToFilter;
    }
    updateList();
    return allRecipes;
  };

  const handleRandomSelected = async () => {
    dispatch(SetReachedLast(true));
    dispatch(SetLoading(true));
    const currentRecipes = await getRecipesToFilter(true);
    const randomRecipeSelected = currentRecipes[random(0, currentRecipes.length - 1)];
    dispatch(SetRecipe(randomRecipeSelected));
    dispatch(SetRecipeId(randomRecipeSelected.id));
    dispatch(SetLoading(false));
    navigate(`/recipe/${randomRecipeSelected.id}`);
  };

  const handleDebounceGetFilteredRecipes = useMemo(
    () => debounce(() => getRecipesToFilter(true), 500),
    [filterText],
  );

  const handleGetFilteredRecipes = useMemo(() => () => getRecipesToFilter(), [categorySelected]);

  const getShoppingListRecipes = async () => {
    await updateShoppingList();
  };

  useEffect(() => {
    if (filterText.length > 0) handleDebounceGetFilteredRecipes();
  }, [filterText]);

  useEffect(() => {
    getShoppingListRecipes();
  }, []);

  useEffect(() => {
    handleGetFilteredRecipes();
  }, [categorySelected]);

  useEffect(() => {
    updateList();
  }, [categorySelected, sort, favoriteSelected]);

  useEffect(() => {
    setListRecipes(allRecipes);
    if (filterText || categorySelected !== 'none' || favoriteSelected) {
      updateList();
    }
  }, [allRecipes]);

  const observer = useRef();

  const getData = () => getAllCategories({ db, currentUserId: currentUser.uid });

  useMemo(async () => {
    const categories = await getData();
    dispatch(SetAllCategories(categories));
  }, [currentUser]);

  const lastRecipeRef = useCallback(
    (node) => {
      if (loading || reachedLast) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
          const moreRecipesFromDb = await getMoreRecipes({
            db,
            currentUserId: currentUser.uid,
          });
          if (!moreRecipesFromDb.length) {
            dispatch(SetReachedLast(true));
          }
          const moreRecipesToAdd = [...allRecipes, ...moreRecipesFromDb];
          dispatch(SetAllRecipes(moreRecipesToAdd));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, allRecipes],
  );

  return (
    <div>
      <div>
        <div className="lg:flex justify-between pt-5 mb-5">
          <div className="text-3xl ml-2 mr-12 lg:pt-6 pb-4">My Recipes</div>
          <div>
            <div className="lg:flex">
              <div className="mx-3">
                <Paper className="p-0.5 background-color" style={{ boxShadow: 'none' }}>
                  <Input
                    className="mx-0.5 pt-4"
                    id="filter"
                    name="filter"
                    placeholder="Search"
                    type="text"
                    autoComplete="off"
                    value={filterText}
                    onChange={handleFilterListChange}
                  />
                </Paper>
              </div>
              <div className="pr-3 lg:pr-1 ml-4 mt-3 mb-4 lg:ml-0 lg:mt-0 lg:mb-0">
                <Paper className="p-0.5 background-color" style={{ boxShadow: 'none' }}>
                  <FormControl variant="standard" className="w-48 min-w-full py-20">
                    <InputLabel className="capitalize">categories</InputLabel>
                    <Select
                      value={categorySelected}
                      label="categories"
                      onChange={handleCategoryChange}
                      className="capitalize"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="none" className="capitalize text-gray-200">
                        <em>None</em>
                      </MenuItem>
                      {allCategories.map((category) => (
                        <MenuItem key={category.name} value={category.name} className="capitalize">
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </div>
            </div>
            <div className="lg:float-right">
              <div className="lg:flex justify-between">
                <div className="flex mt-3 ml-3">
                  {/* <button
                    className="uppercase px-4 py-2 text-xs bg-gray-500 text-blue-100 hover:bg-gray-500 duration-300 w-16 mx-1 h-9"
                    type="button"
                    onClick={() => {
                      handleSortChange();
                    }}
                  >
                    {sort}
                  </button> */}
                  <IconButton
                    type="favorite"
                    handleFunction={handleFavoriteSelected}
                    tooltipText="Favorites"
                    tooltipPosition="top"
                  />
                  <TextButton
                    text="random"
                    handleFunction={handleRandomSelected}
                    tooltipText="Random"
                    tooltipPosition="top"
                  />
                  <TextButton
                    text="clear"
                    handleFunction={reset}
                    mode="alert"
                    tooltipText="Clear"
                    tooltipPosition="top"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {listRecipes.map((recipe, index) => {
        if (listRecipes.length === index + 1) {
          return (
            <div key={recipe.id} ref={lastRecipeRef}>
              <RecipeListItem recipe={recipe} handleCategoryChange={handleCategoryChange} />
            </div>
          );
        }
        return <RecipeListItem key={recipe.id} recipe={recipe} />;
      })}
    </div>
  );
}
