import queryString from 'query-string';

import RouterHolder from 'Routers/RouterHolder';
import AppRouterHolder from 'Routers/AppRouterHolder';
// Adapters
import { IsLoggedIn } from 'contexts/auth/authFunctions';
import { useEffect } from 'react';
import { getUserData } from 'adapters/userAdapters';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { Login, SetLoading, SetRecipeToAddLink, SetRecipeIdFromUrl } from 'redux/actions';

export default function App() {
  const dispatch = useDispatch();
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);

  const [user, loading, error] = IsLoggedIn();

  const storeCurrentUser = (userData) => {
    if (userData?.uid) {
      return dispatch(Login(JSON.parse(JSON.stringify(userData))));
    }
    return dispatch(Login({}));
  };

  const checkUserDeactivated = async (data) => {
    const userData = await getUserData({ db, currentUserId: data.uid });
    if (userData.deactivated) {
      storeCurrentUser({});
    } else {
      storeCurrentUser(user);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      checkUserDeactivated(user);
    }
  }, [user]);

  // Check for toAdd string from url sent from chrome extension
  const params = queryString.parse(window?.location?.search);
  if (params.toAdd) {
    dispatch(SetRecipeToAddLink(params.toAdd));
  }
  // Check for recipe id when hitting /recipe/ route
  const includesRecipeId = window?.location?.pathname?.includes('/recipe/');
  if (includesRecipeId) {
    const recipeId = window.location.pathname.replace('/recipe/', '');
    if (recipeId.length) {
      dispatch(SetRecipeIdFromUrl(recipeId));
    }
  }

  if (loading) {
    dispatch(SetLoading(true));
  }

  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  if (currentUser?.uid) {
    return <AppRouterHolder />;
  }

  return <RouterHolder />;
}
