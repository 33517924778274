const shopppingGroups = {
  0: { id: 0, name: 'unclassifed', showGroup: false, ingredients: {} },
  1: { id: 1, name: 'baking', showGroup: false, ingredients: {} },
  2: { id: 2, name: 'beans, grains and rice', showGroup: false, ingredients: {} },
  3: { id: 3, name: 'beer', showGroup: false, ingredients: {} },
  4: { id: 4, name: 'beverages', showGroup: false, ingredients: {} },
  5: { id: 5, name: 'bread and bakery', showGroup: false, ingredients: {} },
  6: { id: 6, name: 'breakfast and cereal', showGroup: false, ingredients: {} },
  7: { id: 7, name: 'canned goods', showGroup: false, ingredients: {} },
  8: { id: 8, name: 'cheese', showGroup: false, ingredients: {} },
  9: { id: 9, name: 'cleaning', showGroup: false, ingredients: {} },
  10: { id: 10, name: 'coffee', showGroup: false, ingredients: {} },
  11: { id: 11, name: 'condiments and sauces', showGroup: false, ingredients: {} },
  12: { id: 12, name: 'cookies and snacks', showGroup: false, ingredients: {} },
  13: { id: 13, name: 'dairy', showGroup: false, ingredients: {} },
  14: { id: 14, name: 'dried fruits', showGroup: false, ingredients: {} },
  15: { id: 15, name: 'fresh fruits', showGroup: false, ingredients: {} },
  16: { id: 16, name: 'fresh spices', showGroup: false, ingredients: {} },
  17: { id: 17, name: 'floral', showGroup: false, ingredients: {} },
  18: { id: 18, name: 'frozen foods', showGroup: false, ingredients: {} },
  20: { id: 20, name: 'health and beauty', showGroup: false, ingredients: {} },
  21: { id: 21, name: 'health supplements', showGroup: false, ingredients: {} },
  22: { id: 22, name: 'herbs', showGroup: false, ingredients: {} },
  23: { id: 23, name: 'international and ethnic', showGroup: false, ingredients: {} },
  24: { id: 24, name: 'jams and preserves', showGroup: false, ingredients: {} },
  25: { id: 25, name: 'juices', showGroup: false, ingredients: {} },
  26: { id: 26, name: 'kosher', showGroup: false, ingredients: {} },
  27: { id: 27, name: 'meat and poultry', showGroup: false, ingredients: {} },
  28: { id: 28, name: 'nuts', showGroup: false, ingredients: {} },
  29: { id: 29, name: 'oil and vinegar', showGroup: false, ingredients: {} },
  30: { id: 30, name: 'paper products', showGroup: false, ingredients: {} },
  31: { id: 31, name: 'pasta and noodles', showGroup: false, ingredients: {} },
  32: { id: 32, name: 'pet food', showGroup: false, ingredients: {} },
  33: { id: 33, name: 'seafood', showGroup: false, ingredients: {} },
  34: { id: 34, name: 'soups and broth', showGroup: false, ingredients: {} },
  35: { id: 35, name: 'special', showGroup: false, ingredients: {} },
  36: { id: 36, name: 'spices', showGroup: false, ingredients: {} },
  37: { id: 37, name: 'vegetables', showGroup: false, ingredients: {} },
  38: { id: 38, name: 'water', showGroup: false, ingredients: {} },
  39: { id: 39, name: 'wine and spirits', showGroup: false, ingredients: {} },
};

export default shopppingGroups;
