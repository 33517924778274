import { useState, useEffect } from 'react';
import { Checkbox } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PropTypes from 'prop-types';

export default function ShoppingListItem({
  ingredient,
  updateShoppingListRecipe,
  groupId,
  addToShoppingList,
  index,
}) {
  const [checked, setChecked] = useState(false);
  const [checkboxLoading, setCheckboxLoading] = useState(false);
  const [ingredientString, setIngredientString] = useState(false);

  const handleCheckBoxClick = () => {
    setCheckboxLoading(true);
    if (addToShoppingList) {
      ingredient.toAdd = !checked;
    } else {
      ingredient.purchased = !checked;
    }
    updateShoppingListRecipe(ingredient, groupId);
    setTimeout(() => {
      setCheckboxLoading(false);
    }, 500);
  };

  const concatString = () => {
    let string = '';
    if (ingredient.quantity) string += `${ingredient.quantity} `;
    string += ingredient.string;
    setIngredientString(string);
  };

  useEffect(() => {
    setChecked(addToShoppingList ? ingredient.toAdd : ingredient.purchased);
    concatString();
  }, [ingredient.toAdd, ingredient.purchased]);

  if (addToShoppingList || ingredient.toAdd) {
    return (
      <button
        type="button"
        key={ingredient.string}
        className={`bg-white flex-grow w-full pl-3 border-b-2 hover:bg-gray-100 ${
          index === 0 && 'border-t-2'
        } `}
        onClick={handleCheckBoxClick}
      >
        <div className="flex">
          {checkboxLoading && (
            <div className="mt-1.5 ml-3 mr-2.5 mb-3">
              <AutorenewIcon fontSize="small" className="animate-spin" />
            </div>
          )}
          {!checkboxLoading && (
            <Checkbox
              color="success"
              type="checkbox"
              className="mr-2"
              checked={checked}
              onChange={handleCheckBoxClick}
            />
          )}
          <div className="mt-2 capitalize text-left">
            <span
              className={`${!addToShoppingList && checked ? 'line-through text-gray-400' : ''}`}
            >
              {ingredientString}
            </span>
          </div>
        </div>
      </button>
    );
  }
  return null;
}

ShoppingListItem.propTypes = {
  groupId: PropTypes.number,
  ingredient: PropTypes.shape({
    quantity: PropTypes.string,
    string: PropTypes.string,
    purchased: PropTypes.bool,
    toAdd: PropTypes.bool,
  }).isRequired,
  updateShoppingListRecipe: PropTypes.func.isRequired,
  addToShoppingList: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

ShoppingListItem.defaultProps = {
  groupId: null,
  addToShoppingList: false,
};
