import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  SetAlertModalData,
  SetRecipe,
  SetAllRecipes,
  SetAddToShoppingListData,
} from 'redux/actions';
// Hooks
import useUpdateShoppingList from 'hooks/useUpdateShoppingList';
// Components
import RecipeImage from 'components/Recipe/RecipeImage';
import IconButton from '../../IconButton';
// Adapters
import { updateRecipe } from '../../../adapters/recipeAdapters';

export default function RecipeListItem({ recipe }) {
  const dispatch = useDispatch();
  const db = useSelector((rdxState) => rdxState.reducers.db);
  const currentUser = useSelector((rdxState) => rdxState.reducers.currentUser);
  const allRecipes = useSelector((rdxState) => rdxState.reducers.allRecipes);

  const [updatingFavorite, setUpdatingFavorite] = useState(false);
  const [updatingShopping, setUpdatingShopping] = useState(false);
  const updateShoppingList = useUpdateShoppingList();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/recipe/${recipe.id}`);
  };

  const selectRecipe = async () => {
    await dispatch(SetRecipe(recipe));
    handleNavigate();
  };

  const updateAllRecipes = (updatedRecipe) => {
    const recipeFoundInAllRecipesIndex = allRecipes.findIndex(
      (element) => element.id === recipe.id,
    );
    const parsedAllRecipes = JSON.parse(JSON.stringify(allRecipes));
    parsedAllRecipes[recipeFoundInAllRecipesIndex] = updatedRecipe;
    dispatch(SetAllRecipes(parsedAllRecipes));
  };

  const handleFavoriteSelected = async () => {
    setUpdatingFavorite(true);
    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: recipe.id,
      payload: {
        favorite: !recipe.favorite,
      },
    });
    updateAllRecipes(updatedRecipe);
    setUpdatingFavorite(false);
  };

  const addToShoppingList = () => {
    dispatch(
      SetAddToShoppingListData({
        state: true,
        recipeData: recipe,
        previousScreen: 'recipesScreen',
      }),
    );
  };

  const removeFromShoppingList = async () => {
    setUpdatingShopping(true);

    const dataToUpdate = {
      onShoppingList: false,
    };

    const parsedRecipe = JSON.parse(JSON.stringify(recipe));
    parsedRecipe.ingredients.forEach((ingredientsGroup) => {
      ingredientsGroup.ingredients.forEach((ingredient) => {
        ingredient.purchased = false;
        ingredient.toAdd = false;
      });
    });

    dataToUpdate.ingredients = parsedRecipe.ingredients;

    const updatedRecipe = await updateRecipe({
      db,
      currentUserId: currentUser.uid,
      recipeId: parsedRecipe.id,
      payload: dataToUpdate,
    });

    await updateShoppingList();
    updateAllRecipes(updatedRecipe);

    dispatch(
      SetAlertModalData({
        state: false,
        actionText: '',
        cancelText: '',
        bodyText: '',
        handleFunction: () => {},
      }),
    );
    setUpdatingShopping(false);
  };

  const handleAddToShoppingList = async () => {
    if (recipe.onShoppingList) {
      dispatch(
        SetAlertModalData({
          state: true,
          actionText: 'update shopping list',
          actionButtonMode: 'success',
          secondaryText: 'remove',
          secondaryButtonMode: 'alert',
          cancelText: 'cancel',
          cancelButtonMode: 'danger',
          bodyText: 'Do you want to remove this recipe from the Shopping List?',
          handleFunction: addToShoppingList,
          handleSecondaryFunction: removeFromShoppingList,
        }),
      );
    } else {
      addToShoppingList();
    }
  };

  const defineCategories = (cats) => {
    let catsString = '';
    if (cats.length) {
      cats.forEach((cat, idx) => {
        if (idx <= 4) {
          catsString += cat;
          catsString += idx + 1 < cats.length ? ', ' : '';
          catsString += cats.length > 4 && idx === 4 ? '...' : '';
        }
      });
    }
    return catsString;
  };

  return (
    <div key={recipe.id} className={recipe.deleted || recipe.manualList ? 'hidden' : 'block'}>
      <hr />
      <div className="flex">
        <div className="mt-3 mx-1">
          <div className="lg:flex flex-col">
            <div className="mb-1">
              <IconButton
                type="favorite"
                itemToUpdate={recipe.favorite}
                updating={updatingFavorite}
                handleFunction={handleFavoriteSelected}
                tooltipText="Add to Favorites"
                tooltipPosition="top"
              />
            </div>
            <div className="mb-1">
              <IconButton
                type="onShoppingList"
                updating={updatingShopping}
                itemToUpdate={recipe.onShoppingList}
                handleFunction={handleAddToShoppingList}
                tooltipText="Add to Shopping List"
              />
            </div>
          </div>
        </div>
        <div className="flex-initial mt-3 mr-3 ">
          <button type="button" onClick={selectRecipe} className="w-32 h-32">
            <RecipeImage recipeImage={recipe.image} height="h-32" width="w-32" />
          </button>
        </div>
        <div className="flex-initial my-2">
          {recipe.originalURL && (
            <div>
              <a
                name={recipe.title}
                className="text-xs text-blue-400"
                href={recipe.originalURL}
                target="_blank"
                rel="noreferrer"
              >
                {recipe.source}
              </a>
            </div>
          )}
          <div className="flex">
            <div className="flex-initial mr-5">
              <button
                className="capitalize text-xl italic text-left"
                type="button"
                onClick={selectRecipe}
              >
                {recipe.title}
              </button>
            </div>
          </div>
          <button
            type="button"
            onClick={selectRecipe}
            className="capitalize text-xl italic text-left"
          >
            <div className="text-xs italic text-limit">{recipe.description}</div>
            <div className="pt-1">
              {recipe.categories.length > 0 && (
                <div className="text-xs flex">{defineCategories(recipe.categories)}</div>
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

RecipeListItem.propTypes = {
  recipe: PropTypes.shape({
    deleted: PropTypes.bool,
    image: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    favorite: PropTypes.bool,
    description: PropTypes.string,
    originalURL: PropTypes.string,
    source: PropTypes.string,
    onShoppingList: PropTypes.bool,
    ingredients: PropTypes.arrayOf(PropTypes.shape({})),
    categories: PropTypes.arrayOf(PropTypes.string),
    manualList: PropTypes.bool,
  }).isRequired,
};
