import { useEffect, useState } from 'react';

import { Fraction } from 'fractional';
import { useSelector } from 'react-redux';

export default function RecipeIngredients() {
  const recipe = useSelector((rdxState) => rdxState.reducers.recipe);

  const [selectedScale, setSelectedScale] = useState(1);
  const [listRecipe, setListRecipe] = useState({
    ingredients: [],
  });
  const scaleOptions = [
    { name: '1/8', value: 0.125 },
    { name: '1/4', value: 0.25 },
    { name: '1/2', value: 0.5 },
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
  ];

  const scaleRecipe = ({ value }) => {
    setSelectedScale(value);
    const tempRecipe = JSON.parse(JSON.stringify(recipe));
    if (value !== 1) {
      if (tempRecipe.ingredients.length > 0) {
        tempRecipe.ingredients.forEach((ingredientGroup) => {
          if (ingredientGroup.ingredients.length > 0) {
            ingredientGroup.ingredients.forEach((ingredient) => {
              if (ingredient.quantity) {
                ingredient.quantity = new Fraction(ingredient.quantity).multiply(value).toString();
              }
            });
          }
        });
      }
    }
    setListRecipe(tempRecipe);
  };

  useEffect(() => {
    setListRecipe(recipe);
  }, [recipe]);

  return (
    <div className="my-5 pl-3 lg:pl-0">
      <div className="mt-5 mb-1 text-xs font-bold">SCALE</div>
      <div className="flex justify-between text-xs">
        {scaleOptions.map((me) => (
          <button
            key={me.name}
            type="button"
            className={selectedScale === me.value ? 'font-bold' : 'text-blue-600'}
            onClick={() => scaleRecipe(me)}
          >
            {me.name}
          </button>
        ))}
      </div>
      <div className="mt-5 mb-2 text-2xl">INGREDIENTS</div>
      {listRecipe.ingredients.map((item) => (
        <div key={item.index}>
          {item.ingredients.length > 0 && (
            <div className="text-1xl font-bold uppercase">{item.groupName}</div>
          )}
          {item.ingredients.map((ingredient) => (
            <div className="mb-2" key={ingredient.index}>
              {ingredient.quantity && (
                <span className="mr-1.5 font-bold">{ingredient.quantity}</span>
              )}
              <span>{ingredient.string}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
